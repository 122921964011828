import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { ICommodity } from 'src/api/api'

export const useCompanyCommodities = ({ companySlug }) => {
  return useQuery({
    queryKey: ['company-commodities', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCommodities({ companySlug })

      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}

export const useCommodityMutations = () => {
  let { mutateAsync: saveCommodity } = useMutation({
    mutationFn: async (params: Partial<ICommodity>) => {
      if (params.id && params._revision) {
        let [err, response] = await centreAPIs.updateCommodity(params)
        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createCommodity(params)
      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deleteCommodity } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      let [err, response] = await centreAPIs.deleteCommodity({ id })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveCommodity, deleteCommodity }
}
