import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { useStore } from 'react-redux'
import {
  GET_ALL_TICKETS_GRADE_FACTORS_QUERY_KEY,
  useGetAllTicketsGradeFactors,
  useSyncGradeFactors,
} from 'src/api/queries/ticket-applications'
import { Button, Card, HStack, Skeleton, Stack, Text } from 'src/ui'
import { JsonEditor } from 'src/ui/formik'
import { useTicketsContext } from 'src/reports/ticket-applications/context'
import { useToast } from 'src/utils/toast'

function GradeFactorsCard() {
  const { gradeFactorsIsEditing, setGradeFactorsIsEditing } = useTicketsContext()
  const companySlug = useStore().getState().settings.companySlug
  const queryClient = useQueryClient()
  const handleToast = useToast()

  const {
    data: gradeFactors,
    isLoading: getAllGradeFactorsIsLoading,
    isFetching: getAllGradeFactorsIsFetching,
  } = useGetAllTicketsGradeFactors({
    slug: companySlug,
  })
  const { mutateAsync: syncGradeFactorsAsync, isPending: syncGradeFactorsIsLoading } =
    useSyncGradeFactors({ slug: companySlug })

  if (getAllGradeFactorsIsLoading || getAllGradeFactorsIsFetching) {
    return <Skeleton height="238px" />
  }

  const transformedGradeFactors = {
    gradeFactors: gradeFactors.data.map((gradeFactor) => ({
      gradeName: gradeFactor.gradeName,
      displayName: gradeFactor.displayName,
      displayOrder: gradeFactor.displayOrder,
    })),
  }

  if (gradeFactorsIsEditing) {
    return (
      <Card>
        <Formik
          initialValues={{ gradeFactorsInput: transformedGradeFactors }}
          onSubmit={({ gradeFactorsInput: { gradeFactors } }) => {
            syncGradeFactorsAsync({ gradeFactors })
              .then(() => {
                queryClient.refetchQueries({ queryKey: [GET_ALL_TICKETS_GRADE_FACTORS_QUERY_KEY] })
                handleToast({
                  title: 'Success',
                  description: 'Grade Factors updated',
                })
                setGradeFactorsIsEditing(false)
              })
              .catch(() => {
                handleToast({
                  title: 'Error',
                  description: 'Failed to update Grade Factors',
                  status: 'error',
                })
              })
          }}
        >
          {({ handleSubmit, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={8}>
                <HStack justify="space-between">
                  <Stack spacing={1}>
                    <Text fontWeight="bold">Grade Factors</Text>
                    <Text>Please enter in JSON format.</Text>
                  </Stack>
                  <HStack>
                    <Button
                      onClick={() => {
                        setGradeFactorsIsEditing(false)
                      }}
                      size="sm"
                      isDisabled={syncGradeFactorsIsLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="primary"
                      type="submit"
                      isDisabled={syncGradeFactorsIsLoading || !dirty}
                      isLoading={syncGradeFactorsIsLoading}
                    >
                      Save
                    </Button>
                  </HStack>
                </HStack>
                <Stack spacing={3}>
                  <Text color="gray.500" fontSize="sm">
                    Grade Factors
                  </Text>
                  <Field component={JsonEditor} id="gradeFactorsInput" name="gradeFactorsInput" />
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Card>
    )
  } else {
    return (
      <Card>
        <Stack spacing={8}>
          <HStack justify="space-between">
            <Stack spacing={1}>
              <Text fontWeight="bold">Grade Factors</Text>
              <Text>Please enter in JSON format.</Text>
            </Stack>
            <Button
              size="sm"
              onClick={() => {
                setGradeFactorsIsEditing(true)
              }}
            >
              Edit
            </Button>
          </HStack>
          <Stack spacing={3}>
            <Text color="gray.500" fontSize="sm">
              Grade Factors
            </Text>
            <Text
              as="pre"
              border="1px solid"
              borderRadius="4px"
              borderColor="gray.300"
              py={3}
              px={4}
              fontSize="sm"
            >
              {JSON.stringify(transformedGradeFactors, null, 2)}
            </Text>
          </Stack>
        </Stack>
      </Card>
    )
  }
}

export default GradeFactorsCard
