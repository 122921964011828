import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useStore } from 'react-redux'
import {
  GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY,
  useGetConfigurationBySlug,
  useUpsertConfiguration,
} from 'src/api/queries/ticket-applications'
import { TicketsContextProvider, useTicketsContext } from 'src/reports/ticket-applications/context'
import DetailsCard from 'src/reports/ticket-applications/details-card'
import GradeFactorsCard from 'src/reports/ticket-applications/grade-factors-card'
import ConfirmationModal from 'src/reports/ticket-applications/confirmation-modal'
import { EmptyState, FailedState } from 'src/ui/placeholders'
import {
  Button,
  Card,
  Center,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from 'src/ui'

function Tickets() {
  const queryClient = useQueryClient()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const companySlug = useStore().getState().settings.companySlug
  const toast = useToast()
  const { setDetailsIsEditing, setGradeFactorsIsEditing } = useTicketsContext()

  const {
    data: configurationResponse,
    isLoading: getConfigBySlugIsLoading,
    isFetching: getConfigBySlugIsFetching,
    error: getConfigBySlugError,
  } = useGetConfigurationBySlug({
    slug: companySlug,
  })
  const { mutateAsync: upsertConfigurationAsync, isPending: upsertConfigIsLoading } =
    useUpsertConfiguration({ slug: companySlug })

  if (getConfigBySlugIsLoading || getConfigBySlugIsFetching) {
    return (
      <Stack spacing={8}>
        <Skeleton h="56px" />
        <Skeleton h="154px" />
        <Skeleton h="238px" />
      </Stack>
    )
  }

  if (getConfigBySlugError) {
    if (getConfigBySlugError.response.status === 404)
      return (
        <Center flexDirection="column">
          <EmptyState message="Your company hasn’t setup Ticket Application reporting. Click below to start!">
            Error
          </EmptyState>
          <Button
            size="sm"
            colorScheme="primary"
            maxW="143px"
            onClick={() => {
              upsertConfigurationAsync({
                title: 'Ticket Applications',
                enabled: false,
                truckNameEnabled: false,
                truckLicenseEnabled: false,
                truckDriverEnabled: false,
              })
                .then(() => {
                  queryClient.refetchQueries({
                    queryKey: [GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY],
                  })
                  toast({
                    status: 'success',
                    title: 'Success',
                    isClosable: true,
                    description: 'Successfully added company slug',
                    position: 'top-right',
                  })
                })
                .catch(() => {
                  toast({
                    status: 'error',
                    title: 'Error',
                    isClosable: true,
                    description: 'Failed to add company slug',
                    position: 'top-right',
                  })
                })
            }}
            isDisabled={upsertConfigIsLoading}
            isLoading={upsertConfigIsLoading}
          >
            Add Company
          </Button>
        </Center>
      )
    else {
      return <FailedState />
    }
  }

  const {
    data: { enabled, title, truckDriverEnabled, truckLicenseEnabled, truckNameEnabled },
  } = configurationResponse

  return (
    <>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => {
            upsertConfigurationAsync({
              title,
              enabled: !enabled,
              truckLicenseEnabled,
              truckNameEnabled,
              truckDriverEnabled,
            })
              .then(() => {
                queryClient.refetchQueries({
                  queryKey: [GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY],
                })
                toast({
                  status: 'success',
                  title: 'Success',
                  isClosable: true,
                  description: 'Successfully turned off Ticket Applications',
                  position: 'top-right',
                })
                onClose()
                setDetailsIsEditing(false)
                setGradeFactorsIsEditing(false)
              })
              .catch(() => {
                toast({
                  status: 'error',
                  title: 'Error',
                  isClosable: true,
                  description: 'Failed to turn off Ticket Applications',
                  position: 'top-right',
                })
                onClose()
              })
          }}
          isLoading={upsertConfigIsLoading}
        />
      )}
      <Stack spacing={8}>
        <Card>
          <HStack justify="space-between">
            <Text fontWeight="bold">Ticket Applications Report</Text>
            {upsertConfigIsLoading && !enabled ? (
              <Spinner />
            ) : (
              <Switch
                isChecked={enabled}
                onChange={() => {
                  if (enabled) {
                    onOpen()
                  } else {
                    upsertConfigurationAsync({
                      title,
                      enabled: !enabled,
                      truckDriverEnabled,
                      truckNameEnabled,
                      truckLicenseEnabled,
                    })
                      .then(() => {
                        queryClient.refetchQueries({
                          queryKey: [GET_TICKET_CONFIGURATION_BY_SLUG_QUERY_KEY],
                        })
                        toast({
                          status: 'success',
                          title: 'Success',
                          isClosable: true,
                          description: 'Successfully turned on Ticket Applications',
                          position: 'top-right',
                        })
                      })
                      .catch(() => {
                        toast({
                          status: 'error',
                          title: 'Error',
                          isClosable: true,
                          description: 'Failed to turn off Ticket Applications',
                          position: 'top-right',
                        })
                      })
                  }
                }}
              />
            )}
          </HStack>
        </Card>
        {enabled && (
          <>
            <DetailsCard
              title={title}
              truckDriverEnabled={truckDriverEnabled}
              truckLicenseEnabled={truckLicenseEnabled}
              truckNameEnabled={truckNameEnabled}
            />
            <GradeFactorsCard />
          </>
        )}
      </Stack>
    </>
  )
}

function TicketsWithContext() {
  return (
    <TicketsContextProvider>
      <Tickets />
    </TicketsContextProvider>
  )
}

export default TicketsWithContext
