import React, { useMemo, useState } from 'react'
import { Box, Checkbox, Button, Dialog, Input, Grid, Row, Stack, Text, Placeholders } from 'src/ui'
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik'
import { Select as FormikSelect, TextField as FormikTextField } from 'src/ui/formik'
import {
  useCreateMobileBuildMutation,
  useGetBuildBySlug,
  useGetBrandBySlug,
  useGetAllFlavors,
} from 'src/api/queries/branding'
import { useErrorToast, useToast } from 'src/utils/toast'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { AxiosError } from 'axios'

const createBuildFormSchema = Yup.object().shape({
  buildType: Yup.string().required('Required'),
  platform: Yup.string().required('Required'),
})

interface CreateBuildFormProps {
  header: JSX.Element
  company: Company
  flavor: Flavor
}

interface FormikValues {
  emails: string[]
  androidBuildType: string
  buildType: string
  branch: string
  platform: string
  lane: string
  flavorIds: string[]
}

export function CreateAcadiaBuildForm({ header, company, flavor }: CreateBuildFormProps) {
  const toast = useToast()
  const errorToast = useErrorToast()
  const { mutateAsync: createMobileBuild, isPending: isLoading } = useCreateMobileBuildMutation()
  const [isMultiBuildOpen, setIsMultiBuildOpen] = useState(false)
  const buildConfigQuery = useGetBuildBySlug({ slug: company.slug })
  const buildConfigErrorStatusCode = (buildConfigQuery?.error as AxiosError)?.response?.status
  const brandQuery = useGetBrandBySlug({ slug: company.slug })
  const brandErrorStatusCode = (brandQuery?.error as AxiosError)?.response?.status

  if (buildConfigQuery.isLoading || brandQuery.isLoading) {
    return <Placeholders.LoadingState />
  }

  if (buildConfigErrorStatusCode === 404 || brandErrorStatusCode === 404) {
    return (
      <>
        {header}
        <RequiredInfoCheck
          brandCode={brandErrorStatusCode}
          buildConfigCode={buildConfigErrorStatusCode}
          company={company}
        />
      </>
    )
  }

  if (buildConfigQuery.isError || brandQuery.isError) return <Placeholders.FailedState />

  return (
    <>
      {header}
      <Box bg="white" my={8} py={2} borderRadius={3} boxShadow="md">
        <Formik<FormikValues>
          initialValues={{
            emails: [],
            androidBuildType: null,
            buildType: 'unstable',
            branch: null,
            platform: 'both',
            lane: null,
            flavorIds: [flavor.id],
          }}
          validationSchema={createBuildFormSchema}
          validateOnChange={false}
          onSubmit={async (values, formikBag) => {
            await createMobileBuild(
              {
                emails: values.emails,
                androidBuildType: values.androidBuildType
                  ? { data: {}, type: values.androidBuildType }
                  : undefined,
                flavorIds: isMultiBuildOpen ? values.flavorIds : [flavor.id],
                buildType: { data: {}, type: values.buildType },
                iosBranch: values.branch ?? undefined,
                platform: { data: {}, type: values.platform },
                androidBranch: values.branch ?? undefined,
                lane: values.lane ? { data: {}, type: values.lane } : undefined,
              },
              {
                onSuccess: () => {
                  if (isMultiBuildOpen) setIsMultiBuildOpen(false)
                  toast({ title: 'Success', description: 'Created mobile build' })
                  formikBag.resetForm()
                },
                onError: (err) => errorToast(err, 'create', 'mobile build'),
              }
            )
          }}
        >
          {(formikBag) => (
            <Form>
              <Row
                width="100%"
                height="62px"
                justifyContent="space-between"
                alignItems="center"
                px={6}
              >
                <Box fontSize="18px">{company.name}</Box>

                <Row justifyContent="space-between" width="210px" alignItems="right">
                  <Button
                    width="76px"
                    size="sm"
                    colorScheme="primary"
                    type="submit"
                    isLoading={isLoading && !isMultiBuildOpen}
                    isDisabled={isLoading && isMultiBuildOpen}
                  >
                    Build
                  </Button>

                  <Button
                    size="sm"
                    colorScheme="secondary"
                    isDisabled={isLoading}
                    onClick={() => setIsMultiBuildOpen(true)}
                  >
                    Multi-Build
                  </Button>
                </Row>
              </Row>

              <Box px={6}>
                <Grid templateColumns={['1fr', null, null, 'repeat(2, 1fr)']} columnGap={4}>
                  <Field
                    name="buildType"
                    label="Build Type"
                    component={FormikSelect}
                    options={[
                      { label: 'Unstable', value: 'unstable' },
                      { label: 'Development', value: 'development' },
                      { label: 'Sandbox', value: 'sandbox' },
                      { label: 'Production', value: 'production' },
                    ]}
                    selectProps={{ sortOptions: false }}
                  />

                  <Field
                    name="platform"
                    label="Platform"
                    component={FormikSelect}
                    options={[
                      { label: 'Both', value: 'both' },
                      { label: 'iOS', value: 'ios' },
                      { label: 'Android', value: 'android' },
                    ]}
                    selectProps={{ sortOptions: false }}
                  />
                </Grid>

                <Text fontSize="md" fontWeight={500} mt={8} mb={4}>
                  The below values will override type's defaults
                </Text>

                <Grid templateColumns={['1fr', null, null, 'repeat(2, 1fr)']} columnGap={4}>
                  <Field name="branch" label="Branch (Overrides)" component={FormikTextField} />
                </Grid>
              </Box>
              {isMultiBuildOpen && (
                <MultiBuildDialog
                  setIsOpen={setIsMultiBuildOpen}
                  formikBag={formikBag}
                  currentFlavor={flavor}
                />
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

interface RequiredInfoCheckProps {
  brandCode: number
  buildConfigCode: number
  company: Company
}

function RequiredInfoCheck({ brandCode, buildConfigCode, company }: RequiredInfoCheckProps) {
  return (
    <>
      <Placeholders.FailedState
        message={`To create a Build, ${company.name} must first have both a Build Config and Brand`}
      />
      <Stack isInline justifyContent="center">
        {buildConfigCode === 404 && (
          <Button
            colorScheme="yellow"
            as={Link}
            to={`/companies/${company.slug}/build-manager/configuration`}
          >
            Create Build Config
          </Button>
        )}
        {brandCode === 404 && (
          <Button colorScheme="yellow" as={Link} to={`/companies/${company.slug}/branding`}>
            Create Brand
          </Button>
        )}
      </Stack>
    </>
  )
}

const getFilteredFlavors = (inputValue: string, currentFlavor: Flavor, flavors: Flavor[] = []) => {
  const flavorsList = [...flavors].sort((a, b) => {
    if (a.id === currentFlavor.id) return -1
    if (b.id === currentFlavor.id) return 1
    return a.slug < b.slug ? -1 : 1
  })

  if (!inputValue) return flavorsList

  return flavorsList.filter(
    ({ fullName, shortName, slug }) =>
      fullName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
      shortName.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
      slug.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
  )
}

interface MultiBuildDialogProps {
  setIsOpen: (arg: boolean) => void
  formikBag: FormikProps<FormikValues>
  currentFlavor: Flavor
}

function MultiBuildDialog({ setIsOpen, formikBag, currentFlavor }: MultiBuildDialogProps) {
  const [inputValue, setInputValue] = useState('')
  const flavorsQuery = useGetAllFlavors()
  const flavors = flavorsQuery?.data?.data?.data
  const filteredFlavors = useMemo(
    () => getFilteredFlavors(inputValue, currentFlavor, flavors),
    [inputValue, flavors, currentFlavor]
  )

  function onCancel() {
    setIsOpen(false)
  }

  return (
    <Dialog isOpen title="Multi-Build" onClose={onCancel}>
      <Box width="100%">
        <Box>
          {flavors?.length > 5 && (
            <Input
              mb={3}
              size="sm"
              placeholder="Seach"
              value={inputValue}
              onChange={(e) => setInputValue(e.target?.value)}
            />
          )}

          <FieldArray
            name="flavorIds"
            render={(arrayHelpers) => (
              <Stack spacing={3} py={4} px={3} maxH="300px" overflow="scroll">
                {filteredFlavors.map((flavor) => (
                  <Checkbox
                    key={flavor.id}
                    size="md"
                    isChecked={formikBag.values.flavorIds.includes(flavor.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(flavor.id)
                      } else {
                        let idx = formikBag.values.flavorIds.indexOf(flavor.id)
                        if (idx !== -1) arrayHelpers.remove(idx)
                      }
                    }}
                  >
                    <Box
                      as="span"
                      w="100%"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      title={`${flavor.fullName}: ${flavor.slug}`}
                    >
                      {flavor.fullName}: {flavor.slug}
                    </Box>
                  </Checkbox>
                ))}
              </Stack>
            )}
          />
          {flavorsQuery.isSuccess && flavors.length > 0 && filteredFlavors.length === 0 && (
            <Box fontStyle="italic">No matches for "{inputValue}"</Box>
          )}
        </Box>
        <Row justifyContent="flex-end" p={2}>
          <Text color="gray.600" fontSize="15px">
            Selected: {formikBag?.values.flavorIds.length}/{flavors?.length}
          </Text>
        </Row>
        <Row justifyContent="flex-end" mt={3}>
          <Button
            size="sm"
            variant="ghost"
            mr={2}
            isDisabled={formikBag.isSubmitting}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            colorScheme="primary"
            isLoading={formikBag.isSubmitting}
            onClick={() => formikBag.submitForm()}
          >
            Build
          </Button>
        </Row>
      </Box>
    </Dialog>
  )
}
