import { http, HttpResponse, PathParams } from 'msw'
import { ILocation } from 'src/api/api'
import { createElevator, elevators } from 'src/fixtures/bushel-location'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(`${url}/elevators`, () => new HttpResponse(JSON.stringify({ data: elevators }))),
      http.post<PathParams, Partial<ILocation>>(`${url}/elevator`, async ({ request }) => {
        const requestBody = await request.json()
        elevators.push(createElevator(requestBody))
        const statusText = `Successfully added with elevator: ${requestBody.remote_id} to the system.`
        return new HttpResponse(JSON.stringify({ data: requestBody, status: statusText }), {
          status: 201,
        })
      }),
      http.put<PathParams, Partial<ILocation>>(
        `${url}/elevator/:id`,
        async ({ request, params }) => {
          const requestBody = await request.json()
          const id = parseInt(params.id as string)
          const index = elevators.findIndex((elevator) => elevator.id === id)
          elevators[index] = createElevator(requestBody)
          const statusText = `Successfully updated ${requestBody.remote_id}.`
          return new HttpResponse(JSON.stringify({ data: requestBody, status: statusText }))
        }
      ),
    ],
    []
  )
}
