import { useMutation, useQueryClient } from '@tanstack/react-query'
import { brandingRequests, CreateFlavorRequest, UpdateFlavorRequest } from 'src/api/branding'
import { useErrorToast, useToast } from 'src/utils/toast'

export const useCreateFlavorMutation = (onConfirm: () => void) => {
  const toast = useToast()
  const errorToast = useErrorToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CreateFlavorRequest) => brandingRequests.createFlavor(params),
    onSuccess: async (_res, _params) => {
      // Invalidate the query to refresh relevant data
      await queryClient.invalidateQueries({ queryKey: ['get-flavor-by-slug'] })
      toast({ title: 'Success', description: 'Created flavor' })
      onConfirm()
    },
    onError: (err) => {
      errorToast(err, 'create', 'flavor')
    },
  })
}

export const useUpdateFlavorMutation = (onConfirm: () => void) => {
  const toast = useToast()
  const errorToast = useErrorToast()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: UpdateFlavorRequest) => brandingRequests.updateFlavor(params),
    onSuccess: async (_res, _params) => {
      // Invalidate the query to refresh relevant data
      await queryClient.invalidateQueries({ queryKey: ['get-flavor-by-slug'] })
      toast({ title: 'Success', description: 'Updated flavor' })
      onConfirm()
    },
    onError: (err) => {
      errorToast(err, 'update', 'flavor')
    },
  })
}
