import React from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, FormikProps } from 'formik'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { Select, TextAreaField, TextField } from 'src/ui/formik'
import { FormControl, FormLabel } from 'src/ui/form-controls'
import { Box, Stack, Placeholders, Tooltip, InfoOutlineIcon } from 'src/ui'
import ConfigPanel, { PanelState } from 'src/companies/configurations/config-panel'
import { loadCopyDiff, executeCopy } from 'src/companies/configurations/metadata/copy'
import { useCopyDiff } from 'src/companies/copy-utils'
import {
  convertEmptyStringsToNull,
  isFaded,
  representValue,
} from 'src/companies/configurations/utils'
import { useToast } from 'src/utils/toast'
import * as queries from 'src/utils/queries'
import styled from '@emotion/styled'

// DETAIL
///////////////////////////////////////////////////////////////////////////////

interface CompanyMetadataDetailProps {
  companyMetadata: CompanyMetadata
}

const DetailBox = styled(Box)({
  minWidth: 0,
  overflow: 'auto',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    width: 0,
    height: 0,
  },
})

const CompanyMetadataDetail: React.FC<CompanyMetadataDetailProps> = ({ companyMetadata }) => (
  <>
    <Stack isInline spacing={4} align="center" height="80px" px={6}>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="ERP Type">
            ERP Type
          </FormLabel>
          <DetailBox color={isFaded(companyMetadata['erp_type']) ? 'gray.400' : undefined}>
            {representValue(companyMetadata['erp_type'])}
          </DetailBox>
        </FormControl>
      </Box>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="Data Ingestion Type">
            Data Ingestion Type
          </FormLabel>
          <DetailBox
            color={isFaded(companyMetadata['data_ingestion_type']) ? 'gray.400' : undefined}
          >
            {representValue(companyMetadata['data_ingestion_type'])}
          </DetailBox>
        </FormControl>
      </Box>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="Translator Version">
            Translator Version
          </FormLabel>
          <DetailBox
            color={isFaded(companyMetadata['translator_version']) ? 'gray.400' : undefined}
          >
            {representValue(companyMetadata['translator_version'])}
          </DetailBox>
        </FormControl>
      </Box>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="Hubspot Company Id">
            Hubspot Company Id
            <Tooltip
              label="This ID must correlate to one specific company in HubSpot. This is used to sync company configuration information, so please ensure it's accurate."
              aria-label="This ID must correlate to one specific company in HubSpot. This is used to sync company configuration information, so please ensure it's accurate."
              placement="top"
            >
              <InfoOutlineIcon alignSelf="center" ml="1" color="gray.400" />
            </Tooltip>
          </FormLabel>
          <DetailBox
            color={isFaded(companyMetadata['hubspot_company_id']) ? 'gray.400' : undefined}
          >
            {representValue(companyMetadata['hubspot_company_id'])}
          </DetailBox>
        </FormControl>
      </Box>
    </Stack>

    <Stack isInline spacing={4} align="center" height="80px" px={6} bg="gray.50">
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="Android Release Version">
            Android Release Version
          </FormLabel>
          <DetailBox
            color={isFaded(companyMetadata['android_release_version']) ? 'gray.400' : undefined}
          >
            {representValue(companyMetadata['android_release_version'])}
          </DetailBox>
        </FormControl>
      </Box>
      <Box flex={1} minWidth={0}>
        <FormControl height="62px" mb={null}>
          <FormLabel as="div" title="iOS Release Version">
            iOS Release Version
          </FormLabel>
          <DetailBox
            color={isFaded(companyMetadata['ios_release_version']) ? 'gray.400' : undefined}
          >
            {representValue(companyMetadata['ios_release_version'])}
          </DetailBox>
        </FormControl>
      </Box>
      <Box flex={2}></Box>
    </Stack>

    <Stack isInline spacing={4} align="center" px={6} minHeight="80px">
      <Box flex={1} minWidth={0}>
        <FormControl height={null} mb={null}>
          <FormLabel as="div" title="Comments">
            Comments
          </FormLabel>
          <DetailBox color={isFaded(companyMetadata['comments']) ? 'gray.400' : undefined}>
            {representValue(companyMetadata['comments'])}
          </DetailBox>
        </FormControl>
      </Box>
    </Stack>
  </>
)

// FORM
///////////////////////////////////////////////////////////////////////////////

const FormSchema = Yup.object().shape({
  erp_type: Yup.string().nullable(),
  data_ingestions_type: Yup.string().nullable(),
  translator_version: Yup.string().nullable(),
  comments: Yup.string().nullable(),
  hubspot_company_id: Yup.number().typeError('Hubspot Company Id must be a number').nullable(),
})

interface CompanyMetadataFormProps {
  formikProps: FormikProps<any>
  formData: {
    submittedAt: number
    isSubmitting: boolean
  }
  formFieldOptions
}

const CompanyMetadataForm: React.FC<CompanyMetadataFormProps> = ({
  formikProps,
  formData,
  formFieldOptions,
}) => {
  let { submittedAt } = formData
  let { submitForm } = formikProps
  React.useEffect(() => {
    if (!submittedAt) return
    submitForm()
  }, [submittedAt, submitForm])

  return (
    <Form>
      <Stack isInline spacing={4} align="center" height="80px" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            id="erp_type"
            name="erp_type"
            label="ERP Type"
            placeholder="Not Set"
            component={Select}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
            options={formFieldOptions['erp_type'].map((opt) => ({ value: opt, label: opt }))}
          />
        </Box>

        <Box flex={1} minWidth={0}>
          <Field
            id="data_ingestion_type"
            name="data_ingestion_type"
            label="Data Ingestion Type"
            placeholder="Not Set"
            component={Select}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
            options={formFieldOptions['data_ingestion_type'].map((opt) => ({
              value: opt,
              label: opt,
            }))}
          />
        </Box>

        <Box flex={1} minWidth={0}>
          <Field
            id="translator_version"
            name="translator_version"
            label="Translator Version"
            placeholder="Not Set"
            component={Select}
            formControlProps={{
              height: '62px',
              mb: null,
            }}
            options={formFieldOptions['translator_version'].map((opt) => ({
              value: opt,
              label: opt,
            }))}
          />
        </Box>

        <Box flex={1} minWidth={0}>
          <Field
            name="hubspot_company_id"
            label="Hubspot Company Id"
            component={TextField}
            formControlProps={{
              mb: null,
              height: '62px',
            }}
          />
        </Box>
      </Stack>

      <Stack isInline spacing={4} align="center" px={6}>
        <Box flex={1} minWidth={0}>
          <Field
            name="comments"
            label="Comments"
            component={TextAreaField}
            formControlProps={{
              mb: null,
              height: null,
            }}
          />
        </Box>
      </Stack>

      {false && (
        <pre css={{ minWidth: 0, padding: '0 24px', overflow: 'auto', fontSize: 10 }}>
          {JSON.stringify(formikProps, null, 2)}
        </pre>
      )}
    </Form>
  )
}

const useCompanyMetadata = ({ companyId }) => {
  return useQuery({
    queryKey: ['company-metadata', { companyId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreCompanyMetadataByCompanyId({
        companyId,
      })

      if (err) throw err
      return response.data
    },
  })
}

const useCompanyMetadataOptions = () => {
  return useQuery({
    queryKey: ['company-metadata'],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreCompanyMetadataOptions()

      if (err) throw err
      return response.data
    },
  })
}

const newCompanyMetadata = {
  erp_type: null,
  data_ingestion_type: null,
  translator_version: null,
  comments: null,
  hubspot_company_id: null,
  ios_release_version: null,
  android_release_version: null,
}

type CompanyMetadataPanelProps = {
  company: Company
  setCopyDialog: (args: any) => void
}

const CompanyMetadataPanel: React.FC<CompanyMetadataPanelProps> = ({ company, setCopyDialog }) => {
  let [metadataState, setMetadataState] = React.useState<PanelState>('collapsed')
  let toast = useToast()
  const queryClient = useQueryClient()

  let [formData, setFormData] = React.useState({ submittedAt: null, isSubmitting: false })

  const stopEditing = () => {
    setMetadataState('expanded')
    setFormData({ submittedAt: null, isSubmitting: false })
  }

  let companyMetadataQuery = useCompanyMetadata({
    companyId: company.id,
  })

  let companyMetadataOptionsQuery = useCompanyMetadataOptions()

  const saveCompanyMetadata = async ({ companyMetadata }) => {
    let err, response

    if (companyMetadata.id) {
      ;[err, response] = await centreAPIs.updateCentreCompanyMetadata({
        companyMetadata,
      })
    } else {
      companyMetadata.company_id = company.id
      ;[err, response] = await centreAPIs.createCentreCompanyMetadata({
        companyMetadata,
      })
    }

    if (err) throw err
    return [err, response]
  }

  let { mutateAsync: saveCompanyMetadataMutation } = useMutation({
    mutationFn: saveCompanyMetadata,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['company-metadata'] })
      toast({ title: 'Success', description: 'Updated company metadata' })
      stopEditing()
    },
    onError: (error) => {
      throw error // Use error boundary
    },
  })

  let companyMetadata = companyMetadataQuery?.data?.data || newCompanyMetadata
  let companyMetadataOptions = companyMetadataOptionsQuery?.data?.data || []

  let { startCopy } = useCopyDiff({
    company,
    context: { companyMetadata: companyMetadata },
    loadCopyDiff,
    executeCopy,
    setCopyDialog,
  })

  return (
    <ConfigPanel
      label="Metadata"
      formData={formData}
      expanded={['expanded', 'editing'].includes(metadataState)}
      copyPermission="company_properties_copy"
      copyIsDisabled={!(companyMetadata && companyMetadata.id)}
      editPermission="company_properties_edit"
      panelState={metadataState}
      onChange={(e, expanded) => {
        if (metadataState === 'editing') return // ignore changes while editing
        setMetadataState(expanded ? 'expanded' : 'collapsed')
      }}
      onClickCopy={startCopy}
      onClickEdit={() => setMetadataState('editing')}
      onCancelEdit={stopEditing}
      onSave={() => setFormData((state) => ({ ...state, submittedAt: new Date().getTime() }))}
    >
      {queries.areAnyLoading(companyMetadataQuery, companyMetadataOptionsQuery) && (
        <Placeholders.LoadingState mt={8} />
      )}
      {queries.areAnyFailed(companyMetadataQuery, companyMetadataOptionsQuery) && (
        <Placeholders.FailedState mt={8} />
      )}
      {queries.areAllLoaded(companyMetadataQuery, companyMetadataOptionsQuery) &&
        metadataState !== 'editing' && <CompanyMetadataDetail companyMetadata={companyMetadata} />}
      {queries.areAllLoaded(companyMetadataQuery, companyMetadataOptionsQuery) &&
        metadataState === 'editing' && (
          <Formik
            initialValues={companyMetadata}
            validateOnChange={false}
            validationSchema={FormSchema}
            onSubmit={async (values, _formikActions) => {
              setFormData((state) => ({ ...state, isSubmitting: true }))

              try {
                await saveCompanyMetadataMutation({
                  companyMetadata: {
                    ...convertEmptyStringsToNull(values),
                    _revision: companyMetadata._revision,
                  },
                })
              } catch (error) {
                toast({
                  status: 'error',
                  title: 'Error',
                  description:
                    error?.response?.data?.message || 'Failed to update company metadata',
                })
                setFormData({ submittedAt: null, isSubmitting: false })
                _formikActions.setErrors(error?.response?.data?.validation_messages)
              }
            }}
          >
            {(formikProps: FormikProps<any>) => (
              <CompanyMetadataForm
                formikProps={formikProps}
                formData={formData}
                formFieldOptions={companyMetadataOptions}
              />
            )}
          </Formik>
        )}
    </ConfigPanel>
  )
}

export default CompanyMetadataPanel
