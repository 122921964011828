import { http, HttpResponse, PathParams } from 'msw'
import { createCashBidConfig, cashBidConfigs } from 'src/fixtures/cashbid-config'
import { companies } from 'src/fixtures/companies'
import { ICashBidConfig } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(`${url}/cashbid-configs`, ({ request }) => {
        const url = new URL(request.url)
        const searchSlug = url.searchParams.get('company_slug')
        const companyId = companies.find((company) => company.slug === searchSlug)?.id
        const responseData = cashBidConfigs.filter((config) => config.company_id === companyId)
        return new HttpResponse(JSON.stringify({ data: responseData }))
      }),
      http.post<PathParams, Partial<ICashBidConfig>>(
        `${url}/cashbid-config`,
        async ({ request }) => {
          const requestBody = await request.json()
          const newConfig = createCashBidConfig(requestBody)
          cashBidConfigs.push(newConfig)
          return new HttpResponse(JSON.stringify({ data: newConfig }), { status: 201 })
        }
      ),
      http.put<PathParams, any>(`${url}/cashbid-config`, async ({ request }) => {
        const requestBody = await request.json()
        const index = cashBidConfigs.findIndex((config) => config.id === parseInt(requestBody.id))
        cashBidConfigs[index] = createCashBidConfig({ ...cashBidConfigs[index], ...requestBody })
        return new HttpResponse(null)
      }),
      http.delete(`${url}/cashbid-config/:id`, ({ params }) => {
        const { id: paramId } = params
        const id = parseInt(paramId as string)
        const index = cashBidConfigs.findIndex((config) => config.id === id)
        cashBidConfigs.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
    ],
    []
  )
}
