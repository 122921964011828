import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'

export const useCentreTranslators = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: ['centre-translators', { id }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreTranslatorById({ id })
      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}

export const useSaveCentreTranslatorMutation = () => {
  let { mutateAsync: saveCentreTranslator } = useMutation({
    mutationFn: async ({
      translator,
      companySlug,
    }: {
      translator: Translator
      companySlug: string
    }) => {
      if (translator.id) {
        let [err, response] = await centreAPIs.updateCentreTranslator({ translator })
        if (err) throw err
        return response.data
      }
      let [err, response] = await centreAPIs.createCentreTranslator({ translator })
      if (err) throw err

      let [_companyErr, company] = await centreAPIs.getCentreCompanyBySlug({
        slug: companySlug,
      })

      // update centreCompany after creating translator
      let [updateCompanyErr] = await centreAPIs.updateCentreCompany({
        company: {
          ...company,
          translator_id: response.data.data.id,
        },
      })
      if (updateCompanyErr) throw updateCompanyErr
      return response.data
    },
  })

  return { saveCentreTranslator }
}
