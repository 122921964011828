import { useEffect, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'

const usePaginatedQuery = ({
  queryKey = [],
  queryFn,
  queryParams = {},
  clearBetweenRequests = true,
  options = {},
}) => {
  const [data, setData] = useState([])
  const [continuationToken, setContinuationToken] = useState(null)
  const {
    hasNextPage,
    data: infiniteData,
    ...rest
  } = useInfiniteQuery({
    queryKey: [...queryKey, queryParams],
    queryFn: ({ pageParam, signal }) => {
      const cancelTokenSource = axios.CancelToken.source()

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        cancelTokenSource.cancel('Query was cancelled by React Query')
      })

      return queryFn({
        continuationToken: pageParam,
        ...queryParams,
        cancelToken: cancelTokenSource.token,
      })
    },
    initialPageParam: null,
    getNextPageParam: (lastPage) =>
      lastPage?.data?.length > 0 ? lastPage?.continuationToken : undefined,
    ...options,
  })

  useEffect(() => {
    if (clearBetweenRequests || infiniteData?.pages) {
      setData(infiniteData?.pages?.flatMap((response) => response?.data) ?? [])
      setContinuationToken(
        infiniteData?.pages?.[infiniteData?.pages?.length - 1]?.continuationToken ?? null
      )
    }
  }, [clearBetweenRequests, infiniteData?.pages])

  return {
    hasNextPage: hasNextPage ?? true,
    data,
    continuationToken,
    ...rest,
  }
}

export default usePaginatedQuery
