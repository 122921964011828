import { ICommodity, ICompany } from './api'

const baseUri = '/commodity'

export interface GetCommoditiesRequest {
  companySlug: ICompany['slug']
}

export interface CreateCommodityRequest {
  company_id: ICompany['id']
  remote_id: ICommodity['remote_id']
  display_name: ICommodity['display_name']
  default_uom: ICommodity['default_uom']
}

export interface UpdateCommodityRequest {
  id: ICommodity['id']
  company_id: ICompany['id']
  _revision: ICommodity['_revision']
  remote_id: ICommodity['remote_id']
  display_name: ICommodity['display_name']
  default_uom: ICommodity['default_uom']
}

export interface DeleteCommodityRequest {
  id: ICommodity['id']
}

//NEW WAY OF DOING REQUESTS WITH
export const commodityRequests = {
  ///////////////////////////////////////////////////////////////////////////////
  // GET
  ///////////////////////////////////////////////////////////////////////////////

  getCommodities:
    (api) =>
    async ({ companySlug }: GetCommoditiesRequest) => {
      try {
        const response = await api.get(`/commodities?company_slug=${companySlug}`)
        return [null, response]
      } catch (err) {
        return [err, null]
      }
    },

  ///////////////////////////////////////////////////////////////////////////////
  // CREATE
  ///////////////////////////////////////////////////////////////////////////////

  createCommodity: (api) => async (params: Partial<ICommodity>) => {
    try {
      const response = await api.post(baseUri, params)
      return [null, response]
    } catch (err) {
      return [err, null]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // UPDATE
  ///////////////////////////////////////////////////////////////////////////////

  updateCommodity: (api) => async (params: Partial<ICommodity>) => {
    try {
      const response = await api.put(`${baseUri}/${params.id}`, params)
      return [null, response]
    } catch (error) {
      return [error, true]
    }
  },

  ///////////////////////////////////////////////////////////////////////////////
  // DELETE
  ///////////////////////////////////////////////////////////////////////////////

  deleteCommodity:
    (api) =>
    async ({ id }: DeleteCommodityRequest) => {
      try {
        await api.delete(`${baseUri}/${id}`)
        return [null, true]
      } catch (err) {
        return [err, null]
      }
    },
}
