import React from 'react'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, ConfigCard, Column, FormRow, Stack, Dialog } from 'src/ui'
import * as Yup from 'yup'
import { Field, Formik, FormikProps, Form } from 'formik'
import { TextField as FormikTextField } from 'src/ui/formik'
import { ICommodity, ICompany } from 'src/api/api'
import { useCommodityMutations } from 'src/api/queries/commodities'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'

interface CompanyCommodityFormProps {
  id: string
  company: ICompany
  commodities: ICommodity[]
  header: () => void
}

interface CommodityFormProps {
  company_id: Company['id']
  id: number
  remote_id: string
  display_name: string
  default_uom?: string
  _revision?: string
}

const commodityFormSchema = Yup.object().shape({
  remote_id: Yup.string().required('Required'),
  display_name: Yup.string().required('Required'),
})

export const CommodityForm: React.FC<CompanyCommodityFormProps> = ({
  commodities,
  company,
  header,
}) => {
  const { id } = useParams()
  const navigate = useNavigate()
  let { saveCommodity, deleteCommodity } = useCommodityMutations()
  let toast = useToast()
  let [isDeleting, setIsDeleting] = React.useState(false)
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  let [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const queryClient = useQueryClient()
  let currentCommodity = commodities?.find((commodity) => commodity.id === parseInt(id))

  return (
    <>
      <Formik
        initialValues={{
          id: parseInt(id),
          company_id: company.id,
          display_name: currentCommodity?.display_name,
          default_uom: currentCommodity?.default_uom,
          remote_id: currentCommodity?.remote_id,
          _revision: currentCommodity?._revision,
        }}
        validationSchema={commodityFormSchema}
        onSubmit={async ({ id, company_id, display_name, default_uom, remote_id, _revision }) => {
          setIsSubmitting(true)
          await saveCommodity(
            {
              id,
              company_id,
              display_name,
              default_uom,
              remote_id,
              _revision,
            },
            {
              onError: () => {
                toast({
                  status: 'error',
                  description: `Failed to ${id ? 'update' : 'create'} commodity`,
                })
              },
              onSuccess: () => {
                toast({
                  description: `Successfully ${id ? 'updated' : 'created'} commodity`,
                })
              },
              onSettled: async () => {
                await queryClient.invalidateQueries({ queryKey: ['company-commodities'] })
                setIsSubmitting(false)
                navigate(`/companies/${getEnvironment()}/${company.slug}/commodities`)
              },
            }
          )
        }}
      >
        {(formikProps: FormikProps<CommodityFormProps>) => (
          <>
            {header}
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={
                      !hasPermission('company_commodities_edit') ||
                      isDeleting ||
                      !currentCommodity ||
                      isSubmitting
                    }
                    onClick={() => {
                      setShowDeleteDialog(true)
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    as={Link}
                    to={`/companies/${getEnvironment()}/${company.slug}/commodities`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting || isDeleting}
                    isLoading={false}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="primary"
                    isDisabled={
                      !hasPermission('company_commodities_edit') || isSubmitting || isDeleting
                    }
                    isLoading={isSubmitting}
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    Save
                  </Button>
                </Box>
              }
            >
              <Form>
                <Stack spacing={2}>
                  <FormRow rowProps={{ mb: 5 }}>
                    <Field
                      name="display_name"
                      label="Display Name"
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                    />
                    <Field
                      name="remote_id"
                      label="Remote ID"
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                    />
                    <Field
                      name="default_uom"
                      label="Default Unit of Measure"
                      component={FormikTextField}
                      formControlProps={{ height: '62px', mb: null }}
                    />
                  </FormRow>
                </Stack>
              </Form>
            </ConfigCard>
          </>
        )}
      </Formik>
      <Dialog
        title="Delete Commodity"
        isOpen={showDeleteDialog}
        onClose={() => setIsDeleting(false)}
        actions={
          <>
            <Button
              size="sm"
              variant="ghost"
              onClick={() => {
                setShowDeleteDialog(false)
                setIsDeleting(false)
              }}
              mr={2}
            >
              Cancel
            </Button>

            <Button
              size="sm"
              colorScheme="primary"
              isLoading={isDeleting}
              onClick={async () => {
                setIsDeleting(true)
                await deleteCommodity(currentCommodity, {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries({ queryKey: ['company-commodities'] })
                    toast({ description: 'Successfully deleted commodity' })
                    navigate(`/companies/${getEnvironment()}/${company.slug}/commodities`)
                  },
                  onError: () => {
                    toast({ status: 'error', description: 'Failed to delete commodity' })
                  },
                  onSettled: () => {
                    setShowDeleteDialog(false)
                  },
                })
              }}
            >
              Delete
            </Button>
          </>
        }
      >
        <Column>
          <Box>
            <Box>Are you sure you want to delete this commodity</Box>

            {currentCommodity && (
              <ul>
                <li>{currentCommodity.display_name}</li>
              </ul>
            )}
          </Box>
        </Column>
      </Dialog>
    </>
  )
}
