import React from 'react'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import * as api from 'src/utils/api'
import { centreAPIs } from 'src/utils/api'
import { settings } from 'src/store/models'
import MappingsConfig from 'src/companies/oms/mapping/mapping-config'
import { useLocations } from 'src/api/queries/locations'
import NewMappingForm from 'src/companies/oms/mapping/new-mapping-form'
import * as queries from 'src/utils/queries'
import { Placeholders } from 'src/ui'
import { useToast } from 'src/utils/toast'

type MappingIndexProps = {
  availableCommodities: Array<string>
  company: Company
  setShowNewForm?: (val: boolean) => void
  showNewForm: boolean
}

const MappingIndex = ({
  company,
  setShowNewForm = null,
  showNewForm,
  availableCommodities,
}: MappingIndexProps) => {
  const useLocationConfigs = ({ targetAPI, companySlug }) => {
    return useQuery({
      queryKey: ['location-configs', { companySlug, targetAPI }],
      queryFn: async () => {
        let [err, response] = await centreAPIs.getAllLocationsConfig({ companySlug })
        if (err) throw err
        return response.data
      },
      gcTime: 0,
    })
  }
  const toast = useToast()
  const queryClient = useQueryClient()

  const locationsConfigs = useLocationConfigs({
    companySlug: company.slug,
    targetAPI: settings.state.centreAPIEndpoint,
  })

  const locationQuery = useLocations({
    companySlug: company.slug,
  })

  const saveLocationConfig = async ({
    locationConfig,
  }: {
    locationConfig: OfferManagementMapping
    onSuccess?: () => void
  }): api.ApiReturn<OfferManagementMapping> => {
    if (locationConfig.id) {
      let [err, response] = await centreAPIs.updateLocationConfig({
        locationConfig,
      })
      if (err) throw err
      return [err, response]
    }
    let [err, response] = await centreAPIs.createLocationConfig({
      locationConfig: {
        ...locationConfig,
      },
    })
    if (err) throw err
    return [err, response]
  }

  let { mutateAsync: saveLocationConfigMutation } = useMutation({
    mutationFn: saveLocationConfig,
    onSuccess: async (res_, { onSuccess }) => {
      queryClient.invalidateQueries({ queryKey: ['location-configs'] })
      toast({ title: 'Success', description: 'Updated location config' })
      if (onSuccess) onSuccess()
    },
    onError: (_res) => {
      toast({
        status: 'error',
        description: 'Failed to update location config',
      })
    },
  })

  const handleDelete = async ({
    mappingID,
  }: {
    mappingID: string | number
    onSuccess?: () => void
  }): api.ApiReturn<any> => {
    let [err, response] = await centreAPIs.deleteLocationConfig({
      mappingID,
    })
    if (err) {
      throw err
    }

    return [err, response]
  }

  let { mutateAsync: deleteLocationConfig } = useMutation({
    mutationFn: handleDelete,
    onSuccess: async (res_, { onSuccess }) => {
      await queryClient.invalidateQueries({ queryKey: ['location-configs'] })
      toast({
        status: 'success',
        description: 'Deleted location config',
      })
      if (onSuccess) onSuccess()
    },
    onError: (_res) => {
      toast({
        status: 'error',
        description: 'Failed to delete location config',
      })
    },
  })

  function availableLocationsForConfig() {
    if (locationQuery.status === 'success' && locationsConfigs.status === 'success') {
      let configurableLocations: Array<BushelLocation> = []
      locationQuery.data.forEach((location) => {
        if (
          locationsConfigs.data.data.every(
            (locationConfig) => location.id !== locationConfig.elevator_id
          )
        ) {
          configurableLocations.push(location)
        }
      })
      return configurableLocations
    }
  }

  let locationsAvailableForConfig = availableLocationsForConfig()

  return (
    <>
      {queries.areAnyLoading(locationQuery, locationsConfigs) && (
        <Placeholders.LoadingState mt={8} />
      )}
      {queries.areAnyFailed(locationQuery, locationsConfigs) && (
        <Placeholders.LoadingState mt={8} />
      )}
      {queries.areAllLoaded(locationQuery, locationsConfigs) && (
        <>
          {showNewForm && (
            <NewMappingForm
              availableLocations={locationsAvailableForConfig}
              saveLocationConfig={saveLocationConfigMutation}
              setShowNewForm={setShowNewForm}
              company={company}
            />
          )}

          {locationsConfigs.data.data.map((locationConfig) => {
            let configuredLocation: BushelLocation
            locationQuery.data.forEach((location) => {
              if (location.id === locationConfig.elevator_id) {
                configuredLocation = location
              }
            })
            return (
              <MappingsConfig
                availableCommodities={availableCommodities}
                key={locationConfig.id}
                availableLocations={locationsAvailableForConfig}
                configuredLocation={configuredLocation}
                locationConfig={locationConfig}
                saveLocationConfig={saveLocationConfigMutation}
                deleteLocationConfig={deleteLocationConfig}
              />
            )
          })}
        </>
      )}
    </>
  )
}
export default MappingIndex
