import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createOrUpdateCommodity,
  getCommodities,
  getCommodityVarieties,
  UpdateCommodityRequest,
} from 'src/api/microservices/commodities'
import usePaginatedQuery from 'src/utils/microservice/paginated-query'

export const useMicroserviceCompanyCommodities = ({ tenant_id, datasource_id }) =>
  usePaginatedQuery({
    queryKey: ['company-commodities', { tenant_id, datasource_id }],
    queryFn: async ({ continuationToken }) => {
      let [err, response] = await getCommodities({ tenant_id, datasource_id, continuationToken })

      if (err) throw err
      return response.data
    },
  })

export const useMicroserviceCommodityVarieties = () => {
  return useQuery({
    queryKey: ['commodity-varieties'],
    queryFn: async () => {
      let [err, response] = await getCommodityVarieties()

      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}

export const useMicroserviceCommodityMutations = () => {
  let { mutateAsync: saveCommodity } = useMutation({
    mutationFn: async (params: UpdateCommodityRequest) => {
      let [err, response] = await createOrUpdateCommodity(params)
      if (err) throw err
      return [err, response]
    },
  })

  return { saveCommodity }
}
