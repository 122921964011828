import { useQuery } from '@tanstack/react-query'
import { getFeatures } from 'src/utils/api'

export const useFeatures = () => {
  return useQuery({
    queryKey: ['features'],
    queryFn: async () => {
      let [err, response] = await getFeatures()
      if (err) throw err
      return response.data
    },
    gcTime: 0,
  })
}
