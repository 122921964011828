import { http, HttpResponse, PathParams } from 'msw'
import { companies } from 'src/fixtures/companies'
import { ICompany } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(`${url}/companies`, () => new HttpResponse(JSON.stringify({ data: companies }))),
      http.put<PathParams, ICompany>(`${url}/company/:companySlug`, async ({ params, request }) => {
        const requestBody = await request.json()
        const { companySlug } = params
        const index = companies.findIndex((company) => company.slug === companySlug)
        companies[index] = requestBody
        const statusText = `Succcessfully updated ${requestBody.name}.`
        return new HttpResponse(JSON.stringify({ data: requestBody, status: statusText }))
      }),
      http.get(`${url}/company/:companySlug`, ({ params }) => {
        const { companySlug } = params
        if (companySlug === 'undefined') return new HttpResponse(null, { status: 500 })
        if (companySlug === 'testslug') {
          return new HttpResponse(JSON.stringify({ data: { name: 'test-company' } }))
        }
        const company = companies.find((company) => company.slug === companySlug)
        return new HttpResponse(JSON.stringify({ data: company }))
      }),
    ],
    []
  )
}
