import { http, HttpResponse, PathParams } from 'msw'
import {
  GetAllGradeFactorsResponse,
  GetConfigurationBySlugResponse,
  GradeFactor,
  SyncGradeFactorsRequest,
  SyncGradeFactorsResponse,
  UpsertConfigurationRequest,
  UpsertConfigurationResponse,
} from 'src/api/proof-of-yield'
import { CONFIG } from 'src/utils/config'

export const handlers = [
  http.post(`${CONFIG.reporting_api_url}/poy/v1/GetAllGradeFactors`, function () {
    const response: GetAllGradeFactorsResponse = {
      data: [
        {
          createdAt: '2023-07-07T11:15:22Z',
          updatedAt: '2023-07-07T11:15:22Z',
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          displayName: 'Mst',
          displayOrder: 0,
          gradeName: 'Moisture',
        },
      ],
      type: 'api.poyreports.v1.response#GradeFactorsListResponse',
    }
    return new HttpResponse(JSON.stringify(response))
  }),

  http.post(`${CONFIG.reporting_api_url}/poy/v1/GetConfigurationBySlug`, function () {
    const response: GetConfigurationBySlugResponse = {
      data: {
        createdAt: '2023-07-06T14:15:22Z',
        updatedAt: '2023-07-06T14:15:22Z',
        id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
        title: 'Proof of Yield',
        enabled: true,
      },
      type: 'api.poyreports.v1.response#ConfigurationGetResponse',
    }
    return new HttpResponse(JSON.stringify(response))
  }),

  http.post<PathParams, SyncGradeFactorsRequest>(
    `${CONFIG.reporting_api_url}/poy/v1/SyncGradeFactors`,
    async function ({ request }) {
      const body = await request.json()
      const gradeFactors = body?.gradeFactors

      const data = gradeFactors.map<GradeFactor>((gradeFactorConfiguration) => ({
        createdAt: '2023-07-06T14:15:22Z',
        updatedAt: '2023-07-06T20:15:22Z',
        id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
        displayName: gradeFactorConfiguration.displayName,
        displayOrder: gradeFactorConfiguration.displayOrder,
        gradeName: gradeFactorConfiguration.gradeName,
      }))

      const response: SyncGradeFactorsResponse = {
        data: data,
        type: 'api.poyreports.v1.response#GradeFactorsListResponse',
      }

      return new HttpResponse(JSON.stringify(response))
    }
  ),

  http.post<PathParams, UpsertConfigurationRequest>(
    `${CONFIG.reporting_api_url}/poy/v1/UpsertConfiguration`,
    async function ({ request }) {
      const body = await request.json()
      const enabled = body?.enabled
      const title = body?.title

      const response: UpsertConfigurationResponse = {
        data: {
          createdAt: '2023-07-07T14:15:22Z',
          updatedAt: '2023-07-07T14:15:22Z',
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title,
          enabled,
        },
        type: 'api.poyreports.v1.response#ConfigurationGetResponse',
      }

      return new HttpResponse(JSON.stringify(response))
    }
  ),
]
