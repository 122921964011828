import { http, HttpResponse, PathParams } from 'msw'
import {
  customers,
  createCustomer,
  channels,
  createChannel,
  subscriptions,
  createSubscriptions,
  companyUsers,
} from '../fixtures/connect'
import { IConnectCustomer, ICreateConnectSubscription } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      //customers
      http.get(
        `${url}/connect/customers`,
        () => new HttpResponse(JSON.stringify({ data: customers }))
      ),
      http.post<PathParams, Partial<IConnectCustomer>>(
        `${url}/connect/customer`,
        async ({ request }) => {
          const requestBody = await request.json()
          const newCustomer = createCustomer(requestBody)
          customers.push(newCustomer)
          return new HttpResponse(JSON.stringify({ data: newCustomer }), { status: 201 })
        }
      ),
      http.put<PathParams, IConnectCustomer>(`${url}/connect/customer/:id`, async ({ request }) => {
        const requestBody = await request.json()
        const index = customers.findIndex((customer) => customer.id === requestBody.id)
        customers[index] = requestBody
        return new HttpResponse(JSON.stringify({ data: requestBody }))
      }),
      http.delete(`${url}/connect/customer/:id`, ({ params }) => {
        const id = parseInt(params.id as string)
        const index = customers.findIndex((customer) => customer.id === id)
        customers.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
      //channels
      http.get(`${url}/connect/channels`, ({ request }) => {
        const url = new URL(request.url)
        const customerId = parseInt(url.searchParams.get('connect_customer_id'))
        const responseData = channels.filter(
          (channel) => channel.connect_customer_id === customerId
        )
        return new HttpResponse(JSON.stringify({ data: responseData }))
      }),

      http.post(`${url}/connect/channel`, async ({ request }) => {
        const requestBody = await request.json()
        const newChannel = createChannel(requestBody)
        channels.push(newChannel)
        return new HttpResponse(JSON.stringify({ data: newChannel }), { status: 201 })
      }),
      http.put(`${url}/connect/channel/:id`, async ({ request, params }) => {
        const requestBody = await request.json()
        const id = parseInt(params.id as string)
        const editedChannel = createChannel(requestBody)
        const index = channels.findIndex((channel) => channel.id === id)
        channels[index] = editedChannel
        return new HttpResponse(JSON.stringify({ data: editedChannel }), { status: 201 })
      }),
      http.delete(`${url}/connect/channel/:id`, ({ params }) => {
        const id = parseInt(params.id as string)
        const index = channels.findIndex((channel) => channel.id === id)
        channels.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
      //subscriptions
      http.get(`${url}/connect/subscriptions`, ({ request }) => {
        const url = new URL(request.url)
        const channelId = parseInt(url.searchParams.get('connect_channel_id'))
        const responseData = subscriptions.filter(
          (subscription) => subscription.connect_channel_id === channelId
        )
        return new HttpResponse(JSON.stringify({ data: responseData }))
      }),
      http.post<PathParams, ICreateConnectSubscription>(
        `${url}/connect/subscription`,
        async ({ request }) => {
          const requestBody = await request.json()
          const responseData = createSubscriptions(requestBody)
          return new HttpResponse(JSON.stringify({ data: responseData }), { status: 201 })
        }
      ),
      http.delete(`${url}/connect/subscription/:id`, ({ params }) => {
        const id = parseInt(params.id as string)
        const index = subscriptions.findIndex((subscription) => subscription.id === id)
        subscriptions.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
      http.get(
        `${url}/connect/company-users`,
        () => new HttpResponse(JSON.stringify({ data: companyUsers }))
      ),
    ],
    []
  )
}
