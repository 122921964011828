import { http, HttpResponse, PathParams } from 'msw'
import { commodities, createCommodity } from 'src/fixtures/commodities'
import { companies } from 'src/fixtures/companies'
import { ICommodity } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(`${url}/commodities`, ({ request }) => {
        const url = new URL(request.url)
        const slug = url.searchParams.get('company_slug')
        const companyId = companies.find((company) => company.slug === slug)?.id
        const filtered = commodities.filter((commodity) => commodity.company_id === companyId)
        return new HttpResponse(JSON.stringify({ data: filtered }))
      }),
      http.post<PathParams, Partial<ICommodity>>(`${url}/commodity`, async ({ request }) => {
        const requestBody = await request.json()
        const newCommodity = createCommodity(requestBody)
        commodities.push(newCommodity)
        const statusText = `Successfully added with commodity: ${newCommodity.id} to the system.`
        return new HttpResponse(JSON.stringify({ data: newCommodity, status: statusText }), {
          status: 201,
        })
      }),
      http.put<PathParams, Partial<ICommodity>>(
        `${url}/commodity/:id`,
        async ({ params, request }) => {
          const requestBody = await request.json()
          const { id: paramId } = params
          const id = parseInt(paramId as string)
          const editedCommodity = createCommodity(requestBody)
          const index = commodities.findIndex((commodity) => commodity.id === id)
          commodities[index] = editedCommodity
          const statusText = `Successfully updated ${editedCommodity.remote_id}.`
          return new HttpResponse(JSON.stringify({ data: editedCommodity, status: statusText }))
        }
      ),
      http.delete(`${url}/commodity/:id`, ({ params }) => {
        const { id: paramId } = params
        const id = parseInt(paramId as string)
        const index = commodities.findIndex((commodity) => commodity.id === id)
        commodities.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
    ],
    []
  )
}
