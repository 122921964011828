import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { IConnectChannel } from 'src/api/api'

export const useConnectChannels = (env, customerId) => {
  return useQuery({
    queryKey: ['connect-channels', { env, customerId }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getConnectChannels({ customerId })
      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}

export const useConnectChannelMutations = () => {
  let { mutateAsync: saveConnectChannel } = useMutation({
    mutationFn: async (params: Partial<IConnectChannel>) => {
      if (params.id && params._revision) {
        let [err, response] = await centreAPIs.updateConnectChannel(params)
        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createConnectChannel(params)
      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deleteConnectChannel } = useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      let [err, response] = await centreAPIs.deleteConnectChannel({ id })
      if (err) throw err
      return [err, response]
    },
  })

  return { saveConnectChannel, deleteConnectChannel }
}
