import { http, HttpResponse, PathParams } from 'msw'
import { createTranslator, translators } from 'src/fixtures/translator'
import { sequence } from 'src/fixtures/utils'
import { ICentreTranslator } from '../api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(
        `${url}/translator/null`,
        () =>
          new HttpResponse(
            JSON.stringify({
              error: { code: 'Exception', message: 'Translator not found', more: [] },
            }),
            { status: 404 }
          )
      ),
      http.get(`${url}/translator/:id`, ({ params }) => {
        const id = parseInt(params.id as string)
        const translator = translators.find((translator) => translator.id === id)
        return new HttpResponse(JSON.stringify({ data: translator }))
      }),
      http.put<PathParams, ICentreTranslator>(
        `${url}/translator/:id`,
        async ({ request, params }) => {
          const requestBody = await request.json()
          const id = parseInt(params.id as string)
          const index = translators.findIndex((translator) => translator.id === id)
          translators[index] = requestBody
          const statusText = `Successfully updated ${requestBody.url}.`
          return new HttpResponse(JSON.stringify({ data: requestBody, status: statusText }))
        }
      ),
      http.post<PathParams, ICentreTranslator>(`${url}/translator`, async ({ request }) => {
        const requestBody = await request.json()
        const newTranslator = createTranslator({ ...requestBody, id: sequence() })
        translators.push(newTranslator)
        const statusText = `Successfully added with url: ${newTranslator.url} to the system.`
        return new HttpResponse(JSON.stringify({ data: newTranslator, status: statusText }), {
          status: 201,
        })
      }),
    ],
    []
  )
}
