import { http, HttpResponse, PathParams } from 'msw'
import { elevators as elevatorList } from 'src/fixtures/bushel-location'
import { createPosition, positions } from 'src/fixtures/positions'
import { omit } from 'lodash'
import { IPosition } from 'src/api/api'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(`${url}/positions`, async () => {
        return new HttpResponse(JSON.stringify({ data: positions }))
      }),
      http.post<PathParams, { elevators: any[]; remote_elevator_id: string }>(
        `${url}/position`,
        async ({ request }) => {
          const requestBody = await request.json()
          const { elevators } = requestBody
          const newPositions: IPosition[] = []
          if (requestBody.remote_elevator_id) {
            // if created from locations tab
            const addToArray = createPosition(omit(requestBody, 'elevators'))
            newPositions.push(addToArray)
            positions.push(addToArray)
          } else {
            elevators.forEach((id) => {
              const index = elevatorList.findIndex((elevator) => elevator.id === id)
              const remote_elevator_id = elevatorList[index].remote_id
              const positionData = { ...omit(requestBody, 'elevators'), remote_elevator_id }
              const addToArray = createPosition(positionData)
              newPositions.push(addToArray)
              positions.push(addToArray)
            })
          }
          const newPositionIds: number[] = newPositions.map((position) => position.id)
          const statusText = `Successfully added positions: [${newPositionIds}] to the system.`
          return new HttpResponse(JSON.stringify({ data: newPositions, status: statusText }), {
            status: 201,
          })
        }
      ),
      http.delete<PathParams, { ids: number[] }>(`${url}/position`, async ({ request }) => {
        const body = await request.json()
        const ids = body?.ids
        const newPositions = positions.filter((position) => !ids?.includes(position.id))
        positions.splice(0, positions.length, ...newPositions)
        return new HttpResponse(null, { status: 204 })
      }),
      http.delete(`${url}/position/:id`, async ({ params }) => {
        const id = parseInt(params.id as string)
        const index = positions.findIndex((position) => position.id === id)
        positions.splice(index, 1)
        return new HttpResponse(null, { status: 204 })
      }),
      http.put<PathParams, { ids: number[] }>(`${url}/positions`, async ({ request }) => {
        const requestBody = await request.json()
        const ids = requestBody?.ids
        ids.forEach((id) => {
          const position = positions.find((position) => position.id === id)
          Object.assign(position, omit(requestBody, 'ids'))
        })
        const statusText = `Successfully updated positions: [${requestBody.ids}].`
        return new HttpResponse(JSON.stringify({ data: positions, status: statusText }))
      }),
      http.put<PathParams, IPosition>(`${url}/position/:id`, async ({ request, params }) => {
        const requestBody = await request.json()
        const id = parseInt(params.id as string)
        const position = positions.find((position) => position.id === id)
        Object.assign(position, omit(requestBody, 'remote_elevator_id'))
        const statusText = `Successfully updated position: ${id}.`
        return new HttpResponse(JSON.stringify({ data: positions, status: statusText }))
      }),
    ],
    []
  )
}
