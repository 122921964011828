import React from 'react'
import { get, capitalize } from 'lodash'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import CompanyConfigProperties from 'src/companies/configurations/properties'
import CompanyConfiguration from 'src/companies/configurations/company-configuration'
import CompanyConfigAdminUsers from 'src/companies/configurations/admin-users'
import CompanyConfigMetadata from 'src/companies/configurations/metadata'
import CompanyConfigAgtraxApi from 'src/companies/configurations/agtrax-api'
import { centreAPIs } from 'src/utils/api'
import * as queries from 'src/utils/queries'
import { Box, Placeholders } from 'src/ui'
import { useToast } from 'src/utils/toast'
import AgtraxProcessorPanel from './agtrax-processors'
import { saveCompanyArgs } from 'src/companies/configurations.d'
import AggregatorConfigPanel from './aggregator'

export const useCompanyConfig = ({ companySlug }) => {
  return useQuery<CompanyConfig, [string, { companySlug: string }]>({
    queryKey: ['company-config', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreCompanyConfigBySlug({ slug: companySlug })

      if (err) throw err
      return response.data.config
    },
    gcTime: 0,
  })
}

export const useConfigConfigurables = () => {
  return useQuery<CompanyConfigurables, [string]>({
    queryKey: ['config-configurables'],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreConfigurables()

      if (err) throw err
      return response.data.configurables
    },

    gcTime: 0,
  })
}

const fieldGroupToArray = (fields: ConfigurablesGroup) =>
  Object.keys(fields).map((key) => ({ key, ...fields[key] }))

interface CompanyConfigProps {
  company: Company
  setCopyDialog: (args: any) => void
  // route props
  env: string
  slug: string
}

const CompanyConfig: React.FC<CompanyConfigProps> = ({ company, setCopyDialog }) => {
  let toast = useToast()
  const queryClient = useQueryClient()

  const saveCompanyConfig = async ({ companyConfig }: saveCompanyArgs) => {
    let [err, response] = await centreAPIs.updateCentreCompanyConfig({
      slug: company.slug,
      companyConfig,
    })

    if (err) throw err
    return [err, response]
  }

  let companyConfigQuery = useCompanyConfig({ companySlug: company.slug })
  let configConfigurablesQuery = useConfigConfigurables()
  let { mutateAsync: saveCompanyConfigMutation } = useMutation({
    mutationFn: saveCompanyConfig,
    onSuccess: async (_res, { onSuccess }) => {
      await queryClient.invalidateQueries({ queryKey: ['company-config'] })
      toast({ title: 'Success', description: 'Updated company config' })
      if (onSuccess) onSuccess()
    },
    onError: () => {
      toast({
        status: 'error',
        description: 'Failed to update company config',
      })
    },
  })

  return (
    <>
      <Box mt={8} />

      <CompanyConfigProperties company={company} setCopyDialog={setCopyDialog} />

      {queries.areAnyLoading(companyConfigQuery, configConfigurablesQuery) && (
        <Placeholders.LoadingState mt={8} />
      )}
      {queries.areAnyFailed(companyConfigQuery, configConfigurablesQuery) && (
        <Placeholders.FailedState mt={8} />
      )}
      {queries.areAllLoaded(companyConfigQuery, configConfigurablesQuery) && (
        <>
          {[{ path: 'features' }, { path: 'custom' }, { path: 'advanced', numColumns: 2 }].map(
            ({ path, ...props }) => (
              <CompanyConfiguration
                key={path}
                label={capitalize(path)}
                company={company}
                companyConfig={companyConfigQuery.data || {}}
                formFields={fieldGroupToArray(
                  get(configConfigurablesQuery, `data.${path}`, [] as any)
                )}
                saveCompanyConfig={saveCompanyConfigMutation}
                setCopyDialog={setCopyDialog}
                numColumns={props.numColumns}
              />
            )
          )}

          <AggregatorConfigPanel company={company} />

          <AgtraxProcessorPanel company={company} setCopyDialog={setCopyDialog} />

          <CompanyConfigAgtraxApi company={company} setCopyDialog={setCopyDialog} />

          <CompanyConfigMetadata company={company} setCopyDialog={setCopyDialog} />

          <CompanyConfigAdminUsers company={company} />
        </>
      )}
    </>
  )
}

export default CompanyConfig
