import { http, HttpResponse, PathParams } from 'msw'

type FlagrEvaluationRequestBody = {
  flagKey: string
  entityContext: Record<string, string>
}

export const handlers = [
  //  Evaluation
  http.post<PathParams, FlagrEvaluationRequestBody>(
    'https://flagr.bushelops.com/api/v1/evaluation',
    async ({ request }) => {
      const body = await request.json()
      const flagKey = body?.flagKey
      return new HttpResponse(JSON.stringify(makeFlagrResponse(flagKey, 'enabled')))
    }
  ),
]

function makeFlagrResponse(flagKey: string, variantKey?: string) {
  return {
    evalContext: {
      enableDebug: true,
      entityContext: {
        env: 'test',
        username: 'bfriedt',
      },
      entityID: 'randomly_generated_1715540776',
      flagID: 101,
      flagKey,
    },
    evalDebugLog: {
      segmentDebugLogs: [],
    },
    flagID: 101,
    flagKey,
    flagSnapshotID: 2078,
    segmentID: 151,
    timestamp: '2021-05-19T05:07:39Z',
    variantAttachment: {},
    variantID: 99,
    variantKey,
  }
}
