import React from 'react'
import { Formik, Form, Field, FormikProps } from 'formik'
import * as Yup from 'yup'
import { TextField as FormikTextField, Select as FormikSelect } from 'src/ui/formik'
import { Box, Column, Dialog, FormDialog, FormRow, FormLabel, Button } from 'src/ui'
import { STATUS, getEndpoints } from 'src/utils/api'
import { useFeatures } from 'src/api/queries/features'

const TranslatorSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

export const CreateTranslatorDialog: React.FC<DialogProps> = ({ isOpen, onClose, onConfirm }) => (
  <FormDialog title="Create Translator" isOpen={isOpen} onClose={onClose}>
    <Formik
      initialValues={{ name: '', slug: '' }}
      validationSchema={TranslatorSchema}
      onSubmit={(values) => onConfirm(values)}
    >
      {(formikBag: FormikProps<any>) => (
        <Form>
          <>
            <FormDialog.Body>
              <Column>
                <Field name="name" label="Name" component={FormikTextField} />
              </Column>
            </FormDialog.Body>

            <FormDialog.Footer>
              <Button
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  onClose()
                  formikBag.resetForm()
                }}
              >
                Cancel
              </Button>

              <Button size="sm" colorScheme="primary" onClick={() => formikBag.submitForm()}>
                Create
              </Button>
            </FormDialog.Footer>
          </>
        </Form>
      )}
    </Formik>
  </FormDialog>
)

///////////////////////////////////////////////////////////////////////////////

export const DeleteTranslatorDialog: React.FC<DialogProps & { translator: Translator }> = ({
  isOpen,
  onClose,
  onConfirm,
  translator,
}) => (
  <Dialog
    title="Delete Translator"
    isOpen={isOpen}
    onClose={onClose}
    actions={
      <>
        <Button size="sm" variant="ghost" mr={2} onClick={onClose}>
          Cancel
        </Button>

        <Button size="sm" colorScheme="primary" onClick={() => onConfirm(translator)}>
          Delete
        </Button>
      </>
    }
  >
    <Column>
      <Box mb={3}>
        <Box>
          Are you sure you want to delete <strong>{translator && translator.name}</strong>?
        </Box>
      </Box>
    </Column>
  </Dialog>
)

///////////////////////////////////////////////////////////////////////////////

export const StopTranslatorDialog: React.FC<DialogProps & { translator: Translator }> = ({
  isOpen,
  onClose,
  onConfirm,
  translator,
}) => (
  <Dialog
    title="Stop Translator"
    isOpen={isOpen}
    onClose={onClose}
    actions={
      <>
        <Button size="sm" variant="ghost" mr={2} onClick={onClose}>
          Cancel
        </Button>

        <Button size="sm" colorScheme="primary" onClick={onConfirm}>
          Stop
        </Button>
      </>
    }
  >
    <Column>
      <Box mb={3}>
        <Box>
          Are you sure you want to stop <strong>{translator && translator.name}</strong>?
        </Box>
      </Box>
    </Column>
  </Dialog>
)

///////////////////////////////////////////////////////////////////////////////

const TokenSchema = Yup.object().shape({
  token: Yup.string().required('Required'),
})

export const CompanyTokenDialog: React.FC<
  DialogProps & {
    token: string
    environment: Environment
  }
> = ({ isOpen, onClose, onConfirm, token, environment }) => (
  <FormDialog title={`Edit ${environment.name} Token`} isOpen={isOpen} onClose={onClose}>
    <Formik
      initialValues={{ token }}
      validationSchema={TokenSchema}
      onSubmit={(values) => onConfirm(values)}
    >
      {(formikBag: FormikProps<any>) => (
        <Form>
          <>
            <FormDialog.Body>
              <Column>
                <Field name="token" label="Token" component={FormikTextField} />
              </Column>
            </FormDialog.Body>

            <FormDialog.Footer>
              <Button
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  formikBag.resetForm()
                  onClose()
                }}
              >
                Cancel
              </Button>
              <Button size="sm" colorScheme="primary" onClick={() => formikBag.submitForm()}>
                Save
              </Button>
            </FormDialog.Footer>
          </>
        </Form>
      )}
    </Formik>
  </FormDialog>
)

///////////////////////////////////////////////////////////////////////////////

const ReplaySchema = Yup.object().shape({
  environment: Yup.string().required('Required'),
  feature: Yup.string().required('Required'),
  endpoint_id: Yup.string().required('Required'),
  custom_url: Yup.string().when('endpoint_id', ([value], schema) => {
    return value === 'custom'
      ? schema.url('Invalid URL').required('Required')
      : schema.notRequired()
  }),
  custom_token: Yup.string().when('endpoint_id', ([value], schema) =>
    value === 'custom' ? schema.required('Required') : schema.notRequired()
  ),
})

interface ReplayDialogProps extends DialogProps {
  environments: Environment[]
  company: Company
  onConfirm?: (args?: any) => Promise<any>
}

export const ReplayDialog: React.FC<ReplayDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  environments,
  company,
}) => {
  let { data: featuresData, status } = useFeatures()
  let features = featuresData ? featuresData.data : []

  let [endpoints, setEndpoints] = React.useState({
    status: STATUS.loading,
    data: [],
  })

  const loadEndpoints = async () => {
    let [err, response] = await getEndpoints()

    if (err) {
      // @todo: handle err
      setEndpoints({ status: STATUS.failed, data: [] })
      return
    }

    setEndpoints({ status: STATUS.loaded, data: response.data.data })
  }

  React.useEffect(() => {
    if (isOpen) {
      loadEndpoints()
    }
  }, [isOpen])

  return (
    <FormDialog title="Start Replay" isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          environment: '',
          feature: 'all',
          endpoint_id: '',
          custom_url: '',
          custom_token: '',
        }}
        validationSchema={ReplaySchema}
        onSubmit={(values) => {
          if (!features.includes(values.feature) && values.feature !== 'all') {
            values = {
              ...values,
              feature: `${company.slug}~${values.feature}`,
            }
          }
          onConfirm({
            ...values,
            company_slug: company.slug,
            feature: values.feature && values.feature !== 'all' ? values.feature : undefined,
            endpoint:
              values.endpoint_id === 'custom'
                ? null
                : endpoints.data.find((e) => e.id === values.endpoint_id),
          }).then(() => onClose())
        }}
      >
        {(formikBag: FormikProps<any>) => {
          return (
            <>
              <FormDialog.Body>
                <Form>
                  <Column minHeight={150}>
                    <Box mb={3}>
                      <Box>
                        Start a data replay for <strong>{company.name}</strong>.
                      </Box>
                      <FormLabel fontSize="xs" color="gray.400">
                        Company slug will be automatically prepended to all custom features
                      </FormLabel>
                    </Box>

                    <FormRow>
                      <Field
                        id="environment"
                        name="environment"
                        label="Environment"
                        component={FormikSelect}
                        selectProps={{ isClearable: false }}
                        options={environments.map((environment) => ({
                          value: environment.slug,
                          label: environment.name,
                        }))}
                      />
                      <Field
                        id="feature"
                        name="feature"
                        label="Feature"
                        component={FormikSelect}
                        helperText="Company slug will be automatically prepended to all custom features"
                        selectProps={{
                          isClearable: false,
                          isCreatable: true,
                          sortOptions: false,
                          isLoading: status === 'pending',
                          formatCreateLabel: (inputValue) => `Use "${inputValue}"`,
                        }}
                        options={['all', ...features.sort()].map((feature) => ({
                          value: feature,
                          label: feature,
                        }))}
                      />
                    </FormRow>

                    <FormRow>
                      <Field
                        id="endpoint_id"
                        name="endpoint_id"
                        label="Endpoint"
                        component={FormikSelect}
                        selectProps={{ isClearable: false }}
                        options={[
                          { id: 'custom', url: 'Custom' },
                          ...endpoints.data.sort(({ url: ua }, { url: ub }) => (ua < ub ? -1 : 1)),
                        ].map((endpoint) => ({
                          value: endpoint.id,
                          label: endpoint.url,
                        }))}
                      />
                    </FormRow>

                    {formikBag.values.endpoint_id === 'custom' && (
                      <>
                        <FormRow>
                          <Field
                            name="custom_url"
                            label="Custom Endpoint URL"
                            component={FormikTextField}
                          />
                        </FormRow>

                        <FormRow>
                          <Field
                            name="custom_token"
                            label="Custom Endpoint Token"
                            component={FormikTextField}
                          />
                        </FormRow>
                      </>
                    )}
                  </Column>
                </Form>
              </FormDialog.Body>

              <FormDialog.Footer>
                <Button
                  size="sm"
                  variant="ghost"
                  mr={2}
                  onClick={() => {
                    onClose()
                    formikBag.resetForm()
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  colorScheme="primary"
                  onClick={() => formikBag.submitForm()}
                  isDisabled={status === 'pending'}
                >
                  Start
                </Button>
              </FormDialog.Footer>
            </>
          )
        }}
      </Formik>
    </FormDialog>
  )
}
