import _ from 'lodash'
import { http, HttpResponse, PathParams } from 'msw'
import { CONFIG } from 'src/utils/config'
import {
  companies,
  createCompany,
  createEndpoint,
  createSecret,
  createSubscription,
  createTranslator,
  endpoints,
  features,
  secrets,
  translators,
} from '../fixtures/configurator'
import { IEndpoint, IEndpointSubscription } from 'src/api/api'

let token = 'abc123456encryptedtoken'

const noCompanyError = {
  error: {
    message: 'No company found',
    code: 404,
  },
}
function companyInTranslator(companyslug) {
  return companies.findIndex((company) => company.slug === companyslug) !== -1
}

export const handlers = [
  // Configurator companies
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/companies`,
    () => new HttpResponse(JSON.stringify({ data: companies }))
  ),
  http.post<PathParams, Partial<ConfiguratorCompany>>(
    `${CONFIG.configurator_api_url}/api/v1/companies`,
    async ({ request }) => {
      const requestBody = await request.json()
      const company = createCompany(requestBody)
      companies.push(company)
      return new HttpResponse(JSON.stringify({ data: company }), { status: 201 })
    }
  ),
  // Configurator environments
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/system/environments`,
    () => new HttpResponse(JSON.stringify({ data: [{ slug: 'dev', name: 'Development' }] }))
  ),

  // Translators
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/translators`,
    ({ params }) => {
      const { companyslug } = params
      if (companyInTranslator(companyslug)) {
        const returnData = translators.filter((item) => item.company_slug === companyslug)
        return new HttpResponse(JSON.stringify({ data: returnData }))
      }
      return new HttpResponse(JSON.stringify(noCompanyError), { status: 404 })
    }
  ),
  http.post<PathParams, Partial<Translator>>(
    `${CONFIG.configurator_api_url}/api/v1/translators`,
    async ({ request }) => {
      const requestBody = await request.json()
      const newTranslator = createTranslator(requestBody)
      translators.push(newTranslator)
      return new HttpResponse(JSON.stringify({ data: newTranslator }))
    }
  ),
  http.delete(`${CONFIG.configurator_api_url}/api/v1/translators/:translatorId`, ({ params }) => {
    const { translatorId } = params
    const index = translators.findIndex((translator) => translator.id === translatorId)
    translators.splice(index, 1)
    return new HttpResponse(null, { status: 204 })
  }),
  // translator secrets
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets`,
    ({ params }) => {
      const { translatorId } = params
      const returnData = secrets.filter((secret) => secret.translator_id === translatorId)
      return new HttpResponse(JSON.stringify({ data: returnData }))
    }
  ),
  http.post(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets`,
    async ({ request }) => {
      const requestBody = await request.json()
      const newSecret = createSecret(requestBody)
      secrets.push(newSecret)
      return new HttpResponse(JSON.stringify({ data: newSecret }))
    }
  ),
  http.put<PathParams, { value: string }>(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    async ({ params, request }) => {
      const { secretId } = params
      const { value } = await request.json()
      const index = secrets.findIndex((secret) => secret.id === secretId)
      secrets[index].value = value
      return new HttpResponse(null, { status: 204 })
    }
  ),
  http.delete(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    ({ params }) => {
      const { secretId } = params
      const index = secrets.findIndex((secret) => secret.id === secretId)
      secrets.splice(index, 1)
      return new HttpResponse(null, { status: 204 })
    }
  ),
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/secrets/:secretId`,
    ({ params }) => {
      const { secretId } = params
      const viewSecret = secrets.find((secret) => secret.id === secretId)
      return new HttpResponse(JSON.stringify({ data: viewSecret }))
    }
  ),
  // other translator functionality
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/deployments`,
    () => new HttpResponse(JSON.stringify({ data: [] }))
  ),
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/:env/config`,
    () => new HttpResponse(JSON.stringify({ data: [] }))
  ),
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/images/options`,
    () => new HttpResponse(JSON.stringify({ data: [] }))
  ),
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/translators/:translatorId/:env/latest-config`,
    () => new HttpResponse(JSON.stringify(noCompanyError), { status: 404 })
  ),
  http.post(
    'https://router.translator.dev.bushelops.com/api/v1/replay',
    () => new HttpResponse(JSON.stringify({ data: { oops: 'something broke' } }), { status: 500 })
  ),

  // tokens
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/token/:env`,
    ({ params }) =>
      companyInTranslator(params.companyslug)
        ? new HttpResponse(JSON.stringify({ data: { token } }))
        : new HttpResponse(JSON.stringify(noCompanyError), { status: 404 })
  ),
  http.put<PathParams, { newToken: string }>(
    `${CONFIG.configurator_api_url}/api/v1/companies/:companyslug/token/:env`,
    async ({ request }) => {
      const { newToken } = await request.json()
      token = newToken
      return new HttpResponse(null)
    }
  ),

  // endpoints
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/endpoints`,
    () => new HttpResponse(JSON.stringify({ data: endpoints }))
  ),
  http.post<PathParams, Partial<IEndpoint>>(
    `${CONFIG.configurator_api_url}/api/v1/endpoints`,
    async ({ request }) => {
      const requestBody = await request.json()
      const newEndpoint = createEndpoint(requestBody)
      endpoints.push(newEndpoint)
      return new HttpResponse(JSON.stringify({ data: { id: newEndpoint.id } }))
    }
  ),
  http.put<PathParams, Partial<IEndpoint>>(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId`,
    async ({ request, params }) => {
      const requestBody = await request.json()
      const { endpointId } = params
      const index = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
      endpoints[index] = createEndpoint(requestBody)
      return new HttpResponse(JSON.stringify({ data: endpointId }))
    }
  ),
  http.delete(`${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId`, ({ params }) => {
    const { endpointId } = params
    const endpointIndex = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
    endpoints.splice(endpointIndex, 1)
    return new HttpResponse(null, { status: 204 })
  }),
  // endpoint features
  http.get(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/features`,
    () => new HttpResponse(JSON.stringify({ data: features }))
  ),
  // endpoint subscriptions
  http.post<PathParams, Partial<IEndpointSubscription>>(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId/subscription`,
    async ({ request, params }) => {
      const { endpointId } = params
      const requestBody = await request.json()
      const newSubscription = createSubscription(requestBody)
      const index = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
      const alreadyThere = endpoints[index].subscriptions.findIndex(
        (subscription) =>
          subscription.company_slug === newSubscription.company_slug &&
          subscription.environment === newSubscription.environment
      )
      alreadyThere > -1
        ? endpoints[index].subscriptions.splice(alreadyThere, 1, newSubscription)
        : endpoints[index].subscriptions.push(newSubscription)
      return new HttpResponse(null, { status: 204 }) // yes, for some reason 204 is returned on this
    }
  ),
  http.delete<PathParams, Partial<IEndpointSubscription>>(
    `${CONFIG.configurator_api_url}/api/v1/endpoints/:endpointId/subscription`,
    async ({ request, params }) => {
      const requestBody = await request.json()
      const { endpointId } = params
      const endpointIndex = endpoints.findIndex((endpoint) => endpoint.id === endpointId)
      const subscriptionsIndex = endpoints[endpointIndex].subscriptions.findIndex((subscription) =>
        _.isEqual(subscription, createSubscription(requestBody))
      )
      endpoints[endpointIndex].subscriptions.splice(subscriptionsIndex, 1)
      return new HttpResponse(null, { status: 204 })
    }
  ),
]
