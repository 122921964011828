import React, { useState } from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { Button, Column, FormDialog, Placeholders, Row } from 'src/ui'
import { TextField as FormikTextField } from 'src/ui/formik'
import { useGetFlavorBySlug } from 'src/api/queries/branding'
import { AxiosError } from 'axios'
import { useCreateFlavorMutation, useUpdateFlavorMutation } from './flavor-create-hooks'

interface FlavorWrapperProps {
  slug: string
  companies: Company[]
  component: ({ flavor }: { flavor: Flavor }) => JSX.Element
  componentProps?: { [key: string]: any }
}

export function FlavorWrapper({
  slug,
  companies = [],
  component: Component,
  componentProps,
}: FlavorWrapperProps) {
  const flavorQuery = useGetFlavorBySlug({ slug })
  const flavorErrorStatusCode = (flavorQuery?.error as AxiosError)?.response?.status
  const isFlavorMissing = flavorErrorStatusCode === 404
  const [isOpen, setIsOpen] = useState(false)
  let centreCompany = companies.find((c) => c.slug === slug)

  if (flavorQuery.isLoading) return <Placeholders.LoadingState />
  if (flavorQuery.isError && !isFlavorMissing) return <Placeholders.FailedState />

  const flavor = flavorQuery.data?.data?.data

  if (!flavor && isFlavorMissing) {
    return (
      <>
        <Placeholders.FailedState
          message={`Flavor for slug "${slug}" not found in branding service.`}
        />
        <Column mt={3} color="#999">
          <Row justifyContent="center">
            <Button size="sm" colorScheme="primary" onClick={() => setIsOpen(true)}>
              Add Flavor
            </Button>
          </Row>
        </Column>

        <FlavorDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onConfirm={() => setIsOpen(false)}
          company={centreCompany}
        />
      </>
    )
  }

  return <Component flavor={flavor} {...componentProps} />
}

type CreateFlavorDialogProps = DialogProps & {
  company: Company
  flavor?: never
}
type UpdateFlavorDialogProps = DialogProps & {
  company?: never
  flavor: Flavor
}
type FlavorDialogProps = CreateFlavorDialogProps | UpdateFlavorDialogProps

const FlavorSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  shortName: Yup.string().required('Required'),
  slug: Yup.string()
    .strict(true)
    .required('Required')
    .lowercase('Must be lowercase')
    .matches(/^[a-zA-Z0-9]+$/, 'May only contain alphanumeric characters'),
})

export function FlavorDialog({ isOpen, onClose, onConfirm, company, flavor }: FlavorDialogProps) {
  const { mutateAsync: createFlavor } = useCreateFlavorMutation(onConfirm)
  const { mutateAsync: updateFlavor } = useUpdateFlavorMutation(onConfirm)

  const initialValues = flavor
    ? { slug: flavor.slug, shortName: flavor.shortName, fullName: flavor.fullName, id: flavor.id }
    : { slug: company?.slug ?? '', shortName: company?.name ?? '', fullName: company?.name ?? '' }

  return (
    <FormDialog title={`${flavor ? 'Update' : 'Create'} Flavor`} isOpen={isOpen} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={FlavorSchema}
        onSubmit={(values) => (flavor ? updateFlavor(values) : createFlavor(values))}
      >
        {(formikBag: FormikProps<any>) => (
          <>
            <FormDialog.Body>
              <Form>
                <Column>
                  <Field
                    name="slug"
                    label="Slug"
                    component={FormikTextField}
                    InputProps={{ disabled: true }}
                  />
                  <Field name="shortName" label="Short Name" component={FormikTextField} />
                  <Field name="fullName" label="Full Name" component={FormikTextField} />
                </Column>
              </Form>
            </FormDialog.Body>

            <FormDialog.Footer>
              <Button
                size="sm"
                variant="ghost"
                mr={2}
                onClick={() => {
                  formikBag.resetForm()
                  onClose()
                }}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                colorScheme="primary"
                type="submit"
                isLoading={formikBag.isSubmitting}
                onClick={() => formikBag.submitForm()}
              >
                {flavor ? 'Update' : 'Create'}
              </Button>
            </FormDialog.Footer>
          </>
        )}
      </Formik>
    </FormDialog>
  )
}
