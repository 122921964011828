import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Field, Form, Formik } from 'formik'
import { useStore } from 'react-redux'
import {
  GET_CONFIGURATION_BY_SLUG_QUERY_KEY,
  useUpsertConfiguration,
} from 'src/api/queries/proof-of-yield'
import { useProofOfYieldContext } from 'src/reports/proof-of-yield/context'
import { Button, Card, HStack, Input, Stack, Text } from 'src/ui'
import { useToast } from 'src/utils/toast'

const PDF_TITLE_MAX_CHAR_LENGTH = 120

function validatePdfTitle(value: string) {
  if (value.length > PDF_TITLE_MAX_CHAR_LENGTH) {
    return `The PDF Title must be less than ${PDF_TITLE_MAX_CHAR_LENGTH} characters.`
  }
}

function DetailsCard({ title }: { title: string }) {
  const { detailsIsEditing, setDetailsIsEditing } = useProofOfYieldContext()
  const companySlug = useStore().getState().settings.companySlug
  const queryClient = useQueryClient()
  const handleToast = useToast()

  const { mutateAsync: upsertConfigAsync, isPending: isLoading } = useUpsertConfiguration({
    slug: companySlug,
  })

  if (detailsIsEditing) {
    return (
      <Card>
        <Formik
          initialValues={{ poyTitle: title }}
          onSubmit={({ poyTitle }) => {
            upsertConfigAsync({ title: poyTitle, enabled: true })
              .then(() => {
                queryClient.refetchQueries({ queryKey: [GET_CONFIGURATION_BY_SLUG_QUERY_KEY] })
                handleToast({
                  title: 'Success',
                  description: 'Proof of Yield Report title updated',
                })
                setDetailsIsEditing(false)
              })
              .catch(() => {
                handleToast({
                  title: 'Error',
                  description: 'Failed to update Proof of Yield Report title',
                  status: 'error',
                })
              })
          }}
        >
          {({ handleSubmit, errors, isValid, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <Stack spacing={8}>
                <HStack justify="space-between">
                  <Text fontWeight="bold">Details</Text>
                  <HStack>
                    <Button
                      onClick={() => {
                        setDetailsIsEditing(false)
                      }}
                      size="sm"
                      isDisabled={isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="sm"
                      colorScheme="primary"
                      type="submit"
                      isDisabled={!isValid || isLoading || !dirty}
                      isLoading={isLoading}
                    >
                      Save
                    </Button>
                  </HStack>
                </HStack>
                <Stack spacing={3}>
                  <Text color="gray.500" fontSize="sm">
                    PDF Title
                  </Text>
                  <Field as={Input} id="poyTitle" name="poyTitle" validate={validatePdfTitle} />
                  {errors?.poyTitle && <Text color="red">{errors.poyTitle}</Text>}
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Card>
    )
  } else {
    return (
      <Card>
        <Stack spacing={8}>
          <HStack justify="space-between">
            <Text fontWeight="bold">Details</Text>
            <Button
              size="sm"
              onClick={() => {
                setDetailsIsEditing(true)
              }}
            >
              Edit
            </Button>
          </HStack>
          <Stack spacing={3}>
            <Text color="gray.500" fontSize="sm">
              PDF Title
            </Text>
            <Text>{title}</Text>
          </Stack>
        </Stack>
      </Card>
    )
  }
}

export default DetailsCard
