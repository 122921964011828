import { useQuery } from '@tanstack/react-query'
import { getTranslatorSecrets } from '../../utils/api'

export const useSecrets = ({ translatorID }) => {
  return useQuery({
    queryKey: ['translator-secrets', { translatorID }],
    queryFn: async () => {
      let [err, response] = await getTranslatorSecrets({ translatorID })
      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}
