import { http, HttpResponse, PathParams } from 'msw'
import { CONFIG } from 'src/utils/config'
import {
  GetContractsConfigurationBySlugResponse,
  GetExportContractsConfigurationBySlugResponse,
  ContractsUpsertConfigurationRequest,
  ContractsUpsertConfigurationResponse,
} from 'src/api/contracts'

export const handlers = [
  http.post<PathParams, ContractsUpsertConfigurationRequest>(
    `${CONFIG.reporting_api_url}/contracts/v1/ContractsUpsertConfiguration`,
    async function ({ request }) {
      const body = await request.json()
      const enabled = body?.enabled
      const title = body?.title

      const response: ContractsUpsertConfigurationResponse = {
        data: {
          createdAt: '2019-08-24T14:15:22Z',
          id: 'g6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title,
          enabled,
          updatedAt: '2019-08-24T14:15:22Z',
        },
        type: 'api.reporting.contracts.v1.response#ContractsConfigurationGetResponse',
      }

      return new HttpResponse(JSON.stringify(response))
    }
  ),
  http.post(
    `${CONFIG.reporting_api_url}/contracts/v1/GetContractsConfigurationBySlug`,
    async function () {
      const response: GetContractsConfigurationBySlugResponse = {
        data: {
          createdAt: '2019-08-24T14:15:22Z',
          id: 'g6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title: 'Contract',
          enabled: true,
          updatedAt: '2019-08-24T14:15:22Z',
        },
        type: 'api.reporting.contracts.v1.response#ContractsConfigurationGetResponse',
      }
      return new HttpResponse(JSON.stringify(response))
    }
  ),
  http.post(`${CONFIG.reporting_api_url}/contracts/v1/GetExportConfigBySlug`, async function () {
    const response: GetExportContractsConfigurationBySlugResponse = {
      title: 'Contracts',
      type: 'api.reporting.contracts.v1.response#ExportConfigResponse',
      enabled: true,
    }
    return new HttpResponse(JSON.stringify(response))
  }),
]
