import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'

export const useAgtraxApi = ({ companySlug }) => {
  return useQuery({
    queryKey: ['agtrax-apis'],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreAgtraxApiByCompanySlug({ companySlug })
      if (err) throw err
      return response.data
    },
    gcTime: 0,
  })
}

export const useSaveAgtraxMutation = () => {
  let { mutateAsync: saveAgtraxApi } = useMutation({
    mutationFn: async ({ config }: any) => {
      if (config.id) {
        let [err, res] = await centreAPIs.updateCentreAgtraxApi({ config })
        if (err) throw err
        return [err, res]
      }
      let [err, res] = await centreAPIs.createCentreAgtraxApi({ config })
      if (err) throw err
      return [err, res]
    },
  })

  return { saveAgtraxApi }
}
