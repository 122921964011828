import { http, HttpResponse, PathParams } from 'msw'
import { configs, createConfig } from 'src/fixtures/offer-management'
import { companies } from 'src/fixtures/companies'

export const handlers = (urls: string[]) => {
  return urls.reduce((acc, url) => {
    url += '/offer-management-configuration'
    return [
      ...acc,
      http.get(
        `${url}/offermanagement-location-configs`,
        () => new HttpResponse(JSON.stringify({ data: [] }))
      ),
      http.get(`${url}/offermanagement-company-configs`, ({ request }) => {
        const slug = request.headers.get('company-slug')
        const { id } = companies.find((company) => company.slug === slug)
        const responseData = configs.filter((config) => config.company_id === id)
        return new HttpResponse(JSON.stringify({ data: { configs: responseData } }))
      }),
      http.post<PathParams, { futuresConfig: OfferManagementMapping }>(
        `${url}/offermanagement-company-config/create`,
        async ({ request }) => {
          const requestBody = await request.json()
          const newConfig = createConfig(requestBody.futuresConfig)
          configs.push(newConfig)
          const responseData = configs.filter(
            (config) => config.company_id === newConfig.company_id
          )
          return new HttpResponse(JSON.stringify({ data: { configs: responseData } }), {
            status: 201,
          })
        }
      ),
      http.put<PathParams, { futuresConfig: OfferManagementMapping }>(
        `${url}/offermanagement-company-config`,
        async ({ request }) => {
          const requestBody = await request.json()
          const id = requestBody?.futuresConfig?.id
          const futures_product_map_json = requestBody?.futuresConfig?.futures_product_map_json
          const index = configs.findIndex((config) => config.id === id)
          configs[index].futures_product_map_json = futures_product_map_json
          return new HttpResponse(JSON.stringify({ data: { configs: configs[index] } }), {
            status: 201,
          })
        }
      ),
    ]
  }, [])
}
