import { http, HttpResponse, PathParams } from 'msw'
import {
  configurables,
  cmsUsers,
  createCmsUser,
  agtraxProcessor,
  agtraxApi,
  metadataOptions,
  metadata,
  companyConfig,
} from 'src/fixtures/configurations'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      // config
      http.get(
        `${url}/company-config/configurables`,
        () => new HttpResponse(JSON.stringify({ configurables: configurables }))
      ),
      http.get(`${url}/company-config/:companySlug`, ({ params }) => {
        const { companySlug } = params
        return companySlug === 'undefined'
          ? new HttpResponse(null, { status: 500 })
          : new HttpResponse(JSON.stringify({ config: companyConfig }))
      }),
      http.put<PathParams, { config: any }>(
        `${url}/company-config/:companySlug`,
        async ({ request }) => {
          const { config } = await request.json()
          Object.assign(companyConfig, config)
          return new HttpResponse(JSON.stringify({ config: config }))
        }
      ),
      // Agtrax Processor
      http.get(
        `${url}/agtrax-processors`,
        () => new HttpResponse(JSON.stringify({ data: agtraxProcessor }))
      ),
      http.put<PathParams, { id: string }>(`${url}/agtrax-processor/:id`, async ({ request }) => {
        const requestBody = await request.json()
        Object.assign(agtraxProcessor, requestBody)
        const statusText = `Successfully updated processor: ${requestBody.id}.`
        return new HttpResponse(JSON.stringify({ data: requestBody, status: statusText }))
      }),
      // Agtrax API
      http.get(
        `${url}/agtrax-api/:companySlug`,
        () =>
          new HttpResponse(
            JSON.stringify(Object.entries(agtraxApi).length === 0 ? [] : { data: agtraxApi })
          )
      ),
      http.post(`${url}/agtrax-api`, async ({ request }) => {
        const requestBody = await request.json()
        Object.assign(agtraxApi, requestBody)
        return new HttpResponse(JSON.stringify({ data: requestBody }))
      }),
      // metadata
      http.get(
        `${url}/company-metadata/options`,
        () => new HttpResponse(JSON.stringify({ data: metadataOptions }))
      ),
      http.get(
        `${url}/companies-metadata`,
        () => new HttpResponse(JSON.stringify({ data: metadata }))
      ),
      http.put(`${url}/company-metadata/:id`, async ({ request }) => {
        const requestBody = await request.json()
        Object.assign(metadata, requestBody)
        return new HttpResponse(JSON.stringify({ data: requestBody }))
      }),
      // cms users
      http.get(
        `${url}/cms-users/:companySlug`,
        () => new HttpResponse(JSON.stringify({ data: cmsUsers }))
      ),
      http.post(`${url}/create-cms-user`, async ({ request }) => {
        const requestBody = await request.json()
        cmsUsers.push(createCmsUser(requestBody))
        return new HttpResponse(JSON.stringify({ data: cmsUsers }))
      }),
    ],
    []
  )
}
