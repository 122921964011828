import { useQuery, useMutation } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'
import { ICompany, IPosition } from 'src/api/api'
import { BulkDeletePositionRequest } from 'src/api/positions'

export const usePositions = ({ companySlug }) => {
  return useQuery({
    queryKey: ['company-positions', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getPositions({ companySlug })

      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })
}

export const usePositionMutations = () => {
  let { mutateAsync: savePosition } = useMutation({
    mutationFn: async ({
      position,
      companyId,
      location,
    }: {
      position: Position
      location: BushelLocation
      companyId: number
    }) => {
      if (position.id) {
        let [err, response] = await centreAPIs.updatePosition({
          ...position,
        })

        if (err) throw err
        return [err, response]
      }

      let [err, response] = await centreAPIs.createPosition({
        ...position,
        company_id: companyId,
        remote_elevator_id: location.remote_id,
      })

      if (err) throw err
      return [err, response]
    },
  })

  let { mutateAsync: deletePosition } = useMutation({
    mutationFn: async ({ position }: { position: Position }) => {
      let [err, response] = await centreAPIs.deletePosition({
        id: position.id,
      })

      if (err) throw err

      return [err, response]
    },
  })

  return { savePosition, deletePosition }
}

export const useBulkPositionMutations = () => {
  let { mutateAsync: saveBulkPositions } = useMutation({
    mutationFn: async ({
      company_id,
      remote_id,
      display_name,
      elevators,
      ids,
    }: {
      remote_id: IPosition['remote_id']
      display_name: IPosition['display_name']
      elevators?: number[]
      company_id?: ICompany['id']
      ids?: number[]
    }) => {
      // Bulk Create Positions
      if (!ids) {
        let [err, response] = await centreAPIs.bulkCreatePositions({
          company_id,
          remote_id,
          display_name,
          elevators,
        })
        return [err, response]
      }
      // Bulk Update Positions
      let [err, response] = await centreAPIs.bulkUpdatePositions({
        remote_id,
        display_name,
        ids,
        elevators,
      })
      return [err, response]
    },
  })

  let { mutateAsync: deleteBulkPositions } = useMutation({
    mutationFn: async ({ company_id, remote_id, ids }: Partial<BulkDeletePositionRequest>) => {
      if (ids) {
        let [err, response] = await centreAPIs.bulkDeletePositions({
          company_id,
          remote_id,
          ids,
        })
        return [err, response]
      }
    },
  })

  return { saveBulkPositions, deleteBulkPositions }
}
