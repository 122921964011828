import { http, HttpResponse } from 'msw'
import { loggedInUsers, eSignContracts } from 'src/fixtures/reports'

export const handlers = (urls: string[]) => {
  return urls.reduce(
    (acc, url) => [
      ...acc,
      http.get(
        `${url}/reports/logged-in-users/:date`,
        () => new HttpResponse(JSON.stringify({ data: loggedInUsers() }))
      ),
      http.get(
        `${url}/reports/esign-contracts/:date`,
        () => new HttpResponse(JSON.stringify({ data: eSignContracts() }))
      ),
    ],
    []
  )
}
