import { oneOf, sequence } from './utils'
import { ICommodity } from 'src/api/api'
import { companies } from './companies'

export function createCommodity(values: Partial<ICommodity> = {}): ICommodity {
  const id = sequence()
  const remote_id = values.remote_id ?? `remote_id-${id}`
  return {
    id,
    unique_id: `unique_id-${id}`,
    remote_id: `remote_id-${id}`,
    company_id: id,
    display_name: `Commodity ${id}`,
    remote_elevator_id: `remote_elevator_${id}`,
    default_uom: null,
    _revision: `fake-revision-${id}`,
    company_id_remote_id: `${values.company_id ?? id}-${remote_id}`,
    created_at: '2022-10-24T17:41:32+00:00',
    updated_at: '2022-10-24T17:41:32+00:00',
    ...values,
  }
}

function initializeCommodities(): ICommodity[] {
  return companies.reduce(
    (prev, current) => [
      ...prev,
      ...[
        'Corn',
        'Pinto Beans',
        'HRSW',
        'Malting Barley',
        'Black Turtle Beans',
        'Soybeans',
        'Durum',
        'Rye',
        'Sour Patch Kids',
        'Canola',
        'Flax',
        'Oats',
        'Sweet Corn',
      ]
        .slice(0, oneOf([3, 5, 9, 13]))
        .map((name) =>
          createCommodity({
            company_id: current.id,
            display_name: name,
            remote_id: name,
          })
        ),
    ],
    []
  )
}

export const commodities: ICommodity[] = initializeCommodities()
