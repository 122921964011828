import { http, HttpResponse, PathParams } from 'msw'
import {
  GetAllTicketsGradeFactorsResponse,
  GetTicketConfigurationBySlugResponse,
  TicketsIsEnabledForCompanySlugResponse,
  TicketsGradeFactor,
  SyncTicketsGradeFactorsRequest,
  SyncTicketsGradeFactorsResponse,
  TicketsUpsertConfigurationRequest,
  TicketsUpsertConfigurationResponse,
} from 'src/api/ticket-applications'
import { CONFIG } from 'src/utils/config'

export const handlers = [
  http.post(`${CONFIG.reporting_api_url}/tickets/v1/GetAllTicketsGradeFactors`, function () {
    const response: GetAllTicketsGradeFactorsResponse = {
      data: [
        {
          createdAt: '2023-07-07T11:15:22Z',
          updatedAt: '2023-07-07T11:15:22Z',
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          displayName: 'Mst',
          displayOrder: 0,
          gradeName: 'Moisture',
        },
      ],
      type: 'api.reporting.ticket.v1.response#TicketsGradeFactorsListResponse',
    }
    return new HttpResponse(JSON.stringify(response))
  }),

  http.post(`${CONFIG.reporting_api_url}/tickets/v1/GetTicketConfigurationBySlug`, function () {
    const response: GetTicketConfigurationBySlugResponse = {
      data: {
        createdAt: '2019-08-24T14:15:22Z',
        truckLicenseEnabled: true,
        id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5519',
        title: 'Ticket Application',
        truckNameEnabled: false,
        enabled: true,
        truckDriverEnabled: true,
        updatedAt: '2019-08-24T14:15:22Z',
      },
      type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
    }
    return new HttpResponse(JSON.stringify(response))
  }),

  http.post<PathParams, SyncTicketsGradeFactorsRequest>(
    `${CONFIG.reporting_api_url}/tickets/v1/SyncTicketsGradeFactors`,
    async function ({ request }) {
      const body = await request.json()
      const gradeFactors = body?.gradeFactors

      const data = gradeFactors.map<TicketsGradeFactor>((gradeFactorConfiguration) => ({
        createdAt: '2023-07-06T14:15:22Z',
        updatedAt: '2023-07-06T20:15:22Z',
        id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
        displayName: gradeFactorConfiguration.displayName,
        displayOrder: gradeFactorConfiguration.displayOrder,
        gradeName: gradeFactorConfiguration.gradeName,
      }))

      const response: SyncTicketsGradeFactorsResponse = {
        data: data,
        type: 'api.reporting.ticket.v1.response#TicketsGradeFactorsListResponse',
      }

      return new HttpResponse(JSON.stringify(response))
    }
  ),

  http.post(
    `${CONFIG.reporting_api_url}/tickets/v1/TicketsIsEnabledForCompanySlug`,
    async function () {
      const response: TicketsIsEnabledForCompanySlugResponse = {
        enabled: true,
        type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
      }
      return new HttpResponse(JSON.stringify(response))
    }
  ),

  http.post<PathParams, TicketsUpsertConfigurationRequest>(
    `${CONFIG.reporting_api_url}/tickets/v1/TicketsUpsertConfiguration`,
    async function ({ request }) {
      const body = await request.json()
      const enabled = body?.enabled
      const title = body?.title
      const truckLicenseEnabled = body?.truckLicenseEnabled
      const truckNameEnabled = body?.truckNameEnabled
      const truckDriverEnabled = body?.truckDriverEnabled

      const response: TicketsUpsertConfigurationResponse = {
        data: {
          createdAt: '2019-08-24T14:15:22Z',
          truckLicenseEnabled,
          id: 'f6a5bd9f-e60c-40db-96a8-9a62f8eb5518',
          title,
          truckNameEnabled,
          enabled,
          truckDriverEnabled,
          updatedAt: '2019-08-24T14:15:22Z',
        },
        type: 'api.reporting.ticket.v1.response#TicketsConfigurationGetResponse',
      }

      return new HttpResponse(JSON.stringify(response))
    }
  ),
]
