import { http, HttpResponse, PathParams } from 'msw'
import { CONFIG } from 'src/utils/config'
import {
  brands,
  flavors,
  builds,
  createFlavor,
  createBrand,
  updateBrand,
  updateFlavor,
  createBuild,
  updateBuild,
} from 'src/fixtures/branding'
import {
  CreateBrandRequest,
  CreateBuildRequest,
  CreateFlavorRequest,
  UpdateBrandRequest,
  UpdateBuildRequest,
  UpdateFlavorRequest,
} from 'src/api/branding'

export const handlers = [
  /////////////////////// FLAVOR //////////////////////////////////

  http.post<PathParams, { slug: string }>(
    `${CONFIG.branding_url}/GetFlavorBySlug`,
    async ({ request }) => {
      const body = await request.json()
      const slug = body?.slug
      const flavor = flavors.find((flavor) => flavor.slug === slug)
      if (flavor) {
        return new HttpResponse(
          JSON.stringify({
            data: flavor,
            type: 'api.platform.branding.v1.response#FlavorGetResponse',
          })
        )
      }
      return new HttpResponse(
        JSON.stringify({ type: 'api.common#NotFound', message: 'string', status: 404 }),
        { status: 404 }
      )
    }
  ),

  http.post<PathParams, CreateFlavorRequest>(
    `${CONFIG.branding_url}/CreateFlavor`,
    async ({ request }) => {
      const requestBody = await request.json()
      const newFlavor = createFlavor(requestBody)
      flavors.push(newFlavor)
      return new HttpResponse(
        JSON.stringify({
          data: newFlavor,
          type: 'api.platform.branding.v1.response#FlavorGetResponse',
        }),
        { status: 200 }
      )
    }
  ),

  http.post<PathParams, UpdateFlavorRequest>(
    `${CONFIG.branding_url}/UpdateFlavor`,
    async ({ request }) => {
      const requestBody = await request.json()
      const updatedFlavor = updateFlavor(requestBody)
      return new HttpResponse(
        JSON.stringify({
          data: updatedFlavor,
          type: 'api.platform.branding.v1.response#FlavorGetResponse',
        })
      )
    }
  ),

  http.post(`${CONFIG.branding_url}/GetAllFlavors`, () => {
    return new HttpResponse(
      JSON.stringify({
        data: flavors,
        type: 'api.platform.branding.v1.response#FlavorListResponse',
      })
    )
  }),

  /////////////////////// BRAND /////////////////////////////////

  http.post<PathParams, { slug: string }>(
    `${CONFIG.branding_url}/GetBrandBySlug`,
    async ({ request }) => {
      const body = await request.json()
      const slug = body?.slug
      const flavor = flavors.find((flavor) => flavor.slug === slug)
      const brand = brands.find((brand) => brand.flavorId === flavor?.id)
      if (flavor && brand) {
        return new HttpResponse(
          JSON.stringify({
            data: brand,
            type: 'api.platform.branding.v1.response#BrandGetResponse',
          })
        )
      }
      return new HttpResponse(
        JSON.stringify({ type: 'api.common#NotFound', message: 'string', status: 404 }),
        { status: 404 }
      )
    }
  ),

  http.post<PathParams, CreateBrandRequest>(
    `${CONFIG.branding_url}/CreateBrand`,
    async ({ request }) => {
      const requestData = await request.json()
      const newBrand = createBrand(requestData)
      brands.push(newBrand)
      return new HttpResponse(
        JSON.stringify({ data: newBrand, type: 'api.platform.branding.v1#CreateBrandSuccess' })
      )
    }
  ),

  http.post<PathParams, UpdateBrandRequest>(
    `${CONFIG.branding_url}/UpdateBrand`,
    async ({ request }) => {
      const requestData = await request.json()
      const updatedBrand = updateBrand(requestData)
      return new HttpResponse(
        JSON.stringify({
          data: updatedBrand,
          type: 'api.platform.branding.v1.response#BrandGetResponse',
        })
      )
    }
  ),

  ///////////////////////// BUILD ////////////////////////////

  http.post<PathParams, { slug: string }>(
    `${CONFIG.branding_url}/GetBuildBySlug`,
    async ({ request }) => {
      // example for a "not found" state
      const body = await request.json()
      const slug = body?.slug
      const flavor = flavors.find((flavor) => flavor.slug === slug)
      const build = builds.find((build) => build.flavorId === flavor?.id)
      if (flavor && build) {
        return new HttpResponse(
          JSON.stringify({
            data: build,
            type: 'api.platform.branding.v1.response#BuildGetResponse',
          })
        )
      }
      return new HttpResponse(
        JSON.stringify({ type: 'api.common#NotFound', message: 'string', status: 404 }),
        { status: 404 }
      )
    }
  ),

  http.post<PathParams, CreateBuildRequest>(
    `${CONFIG.branding_url}/CreateBuild`,
    async ({ request }) => {
      const requestData = await request.json()
      const newBuild = createBuild(requestData)
      builds.push(newBuild)
      return new HttpResponse(
        JSON.stringify({
          data: newBuild,
          type: 'api.platform.branding.v1.response#BuildGetResponse',
        })
      )
    }
  ),

  http.post<PathParams, UpdateBuildRequest>(
    `${CONFIG.branding_url}/UpdateBuild`,
    async ({ request }) => {
      const requestData = await request.json()
      const updatedBuild = updateBuild(requestData)
      return new HttpResponse(
        JSON.stringify({
          data: updatedBuild,
          type: 'api.platform.branding.v1.response#BuildGetResponse',
        })
      )
    }
  ),

  http.post(`${CONFIG.branding_url}/CreateMobileBuild`, () => {
    return new HttpResponse(
      JSON.stringify({
        type: 'api.common.response#EmptyResponse',
      })
    )
  }),
]
