import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, ConfigCard, Placeholders } from 'src/ui'
import * as Yup from 'yup'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Select, TextField as FormikTextField } from 'src/ui/formik'
import { ICompany, IMicroserviceCommodity, IMicroserviceCommodityVariety } from 'src/api/api'
import { useMicroserviceCommodityMutations } from 'src/api/queries/microservices/commodities'
import { useToast } from 'src/utils/toast'
import { hasPermission } from 'src/utils/permissions'
import { getEnvironment } from 'src/utils'
import { VStack } from '@chakra-ui/react'
import { useMicroserviceDatasource } from 'src/api/queries/microservices/tenants'
import * as queries from 'src/utils/queries'
import { useCommodityContext } from 'src/companies/commodities/v2/commodity-context'

interface CompanyCommodityFormProps {
  company: ICompany
  commodities: IMicroserviceCommodity[]
  commodityVarieties: IMicroserviceCommodityVariety[]
  header: () => void
}

interface CommodityFormProps {
  tenant_id: string
  tenant_name: string
  datasource_id: string
  datasource_name: string
  source_id?: string
  source_display_name?: string
  display_name?: string
  default_uom?: string
  commodity_variety_id?: string
}

const commodityFormSchema = Yup.object().shape({
  source_id: Yup.string().required('Required'),
})

const FormField = ({ name, label, ...rest }) => (
  <Field
    name={name}
    label={label}
    component={FormikTextField}
    formControlProps={{ height: '62px', width: '320px' }}
    {...rest}
  />
)

export const CommodityForm: React.FC<CompanyCommodityFormProps> = ({ header }) => {
  const { id } = useParams()
  const { company, commodities, commodityVarieties } = useCommodityContext()
  let datasourceQuery = useMicroserviceDatasource({ datasource_id: company.datasource_id })
  let datasource = datasourceQuery?.data

  let { saveCommodity } = useMicroserviceCommodityMutations()
  let toast = useToast()
  let [isSubmitting, setIsSubmitting] = React.useState(false)
  const queryClient = useQueryClient()
  let currentCommodity = commodities?.find((commodity) => commodity.id === id)
  let currentVariety = commodityVarieties?.find(
    (variety) => variety.commodityVarietyId === currentCommodity?.commodityId
  )

  const navigate = useNavigate()

  if (queries.areAnyLoading(datasourceQuery)) return <Placeholders.LoadingState />
  return (
    <>
      <Formik
        initialValues={{
          tenant_id: datasource?.tenant?.id,
          tenant_name: datasource?.tenant?.name,
          display_name: currentCommodity?.displayName,
          default_uom: currentCommodity?.defaultUom,
          datasource_id: datasource?.id,
          datasource_name: datasource?.slug,
          source_id: currentCommodity?.sourceId,
          source_display_name: currentCommodity?.sourceDisplayName,
          commodity_variety_id: currentVariety?.commodityVarietyId,
        }}
        validationSchema={commodityFormSchema}
        onSubmit={async ({
          tenant_id,
          datasource_id,
          source_id,
          source_display_name,
          display_name,
          default_uom,
          commodity_variety_id,
        }) => {
          setIsSubmitting(true)
          await saveCommodity(
            {
              tenantId: tenant_id,
              datasourceId: datasource_id,
              sourceId: source_id,
              sourceDisplayName: source_display_name,
              displayName: display_name,
              defaultUom: default_uom,
              commodityVarietyId: commodity_variety_id,
            },
            {
              onError: () => {
                toast({
                  status: 'error',
                  description: `Failed to ${id ? 'update' : 'create'} commodity`,
                })
              },
              onSuccess: () => {
                toast({
                  description: `Successfully ${id ? 'updated' : 'created'} commodity`,
                })
              },
              onSettled: async () => {
                await queryClient.invalidateQueries({ queryKey: ['company-commodities'] })
                setIsSubmitting(false)
                navigate(`/companies/${getEnvironment()}/${company.slug}/commodities`)
              },
            }
          )
        }}
      >
        {(formikProps: FormikProps<CommodityFormProps>) => (
          <>
            {header}
            <ConfigCard
              header={
                <Box mb={6}>
                  <Button
                    as={Link}
                    to={`/companies/${getEnvironment()}/${company.slug}/commodities`}
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="secondary"
                    isDisabled={isSubmitting}
                    isLoading={false}
                  >
                    CANCEL
                  </Button>
                  <Button
                    width="76px"
                    size="sm"
                    mr={2}
                    colorScheme="primary"
                    isDisabled={!hasPermission('company_commodities_edit') || isSubmitting}
                    isLoading={isSubmitting}
                    onClick={() => {
                      formikProps.submitForm()
                    }}
                  >
                    {id ? 'Save' : 'Add'}
                  </Button>
                </Box>
              }
            >
              <Form>
                <VStack align="start" spacing={6}>
                  <FormField name="tenant_id" label="Tenant ID" disabled={true} />
                  <FormField name="tenant_name" label="Tenant Name" disabled={true} />
                  <FormField name="datasource_id" label="Data Source ID" disabled={true} />
                  <FormField name="datasource_name" label="Data Source Name" disabled={true} />
                  <FormField name="source_id" label="Source ID" disabled={id !== undefined} />
                  {id && <FormField name="source_display_name" label="Source Display Name" />}
                  <FormField name="display_name" label="Display Name" />
                  <FormField name="default_uom" label="Default Unit of Measure" />
                  <FormField
                    name="commodity_variety_id"
                    label="Commodity Variety"
                    component={Select}
                    options={commodityVarieties?.map((variety) => ({
                      value: variety.commodityVarietyId,
                      label: `${variety.className}, ${variety.groupName}, ${variety.subgroupName}, ${variety.varietyName}`,
                    }))}
                  />
                </VStack>
              </Form>
            </ConfigCard>
          </>
        )}
      </Formik>
    </>
  )
}
