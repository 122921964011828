import React, { useEffect } from 'react'
import { connect, Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import store, { Dispatch, RootState } from 'src/store'
import { AuthRoute, defaultRoutePath, FlexRoutes, InvalidPermissions, NotFound } from 'src/routes'
import { hasPermission, hasUserRoles } from 'src/utils/permissions'
import { GlobalStyles } from 'src/styles'
import { theme } from 'src/ui'
import Layout from 'src/layout'
import Notifications from 'src/app/notifications'
import Companies from 'src/companies'
import Reports from 'src/reports'
import Translators from 'src/translators'
import Connect from 'src/connect'
import { CreateCompanyDialog } from 'src/companies/dialogs'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'
import { AxiosInterceptorProvider } from 'src/utils/api'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import MockProvider from 'src/mocks/MockProvider'

type AppProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const App: React.FC<AppProps> = ({
  settings,
  setSettingsState,
  createCompany,
  loadEnvironments,
}) => {
  let userDoesHaveRoles = hasUserRoles()

  useEffect(() => {
    if (userDoesHaveRoles) {
      loadEnvironments()
    }
  }, [loadEnvironments, userDoesHaveRoles])

  return (
    <>
      <GlobalStyles />
      <Notifications />

      <Layout>
        <FlexRoutes>
          <Route path="/" element={<Navigate to={defaultRoutePath()} replace />} />
          {!userDoesHaveRoles && <Route path="*" element={<InvalidPermissions />} />}
          <Route
            path="/no-auth"
            element={<AuthRoute as={() => <Navigate to="/companies" replace />} />}
          />
          {hasPermission('nav_company') && (
            <Route path="/companies/*" element={<AuthRoute as={Companies} />} />
          )}
          {hasPermission('nav_translators') && (
            <Route path="/translators/*" element={<AuthRoute as={Translators} />} />
          )}
          {hasPermission('billing_reports') && (
            <Route path="/reports/*" element={<AuthRoute as={Reports} />} />
          )}
          {hasPermission('nav_connect') && (
            <Route path="/connect/*" element={<AuthRoute as={Connect} />} />
          )}
          <Route path="*" element={<NotFound />} />
        </FlexRoutes>

        <CreateCompanyDialog
          isOpen={settings.showCompanyCreateDialog}
          onClose={() => {
            setSettingsState({ showCompanyCreateDialog: false, showCompanySelectorDialog: true })
          }}
          onConfirm={(values) => createCompany({ company: values })}
        />
      </Layout>
    </>
  )
}

const mapState = ({ settings }: RootState) => ({ settings })

const mapDispatch = ({ settings, companies, environments }: Dispatch) => ({
  setSettingsState: settings.setState,
  createCompany: companies.createCompany,
  loadEnvironments: environments.loadAll,
})

const AppContainer = connect(mapState, mapDispatch)(App)

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
})

const AppProviders: React.FC = () => (
  <MockProvider>
    <BrowserRouter>
      <AxiosInterceptorProvider>
        <ChakraProvider theme={theme}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <AppContainer />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Provider>
        </ChakraProvider>
      </AxiosInterceptorProvider>
    </BrowserRouter>
  </MockProvider>
)

export default AppProviders
