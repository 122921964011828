import React, { useEffect, useState } from 'react'

async function enableApiMocking() {
  if (typeof window !== 'undefined') {
    const { worker } = require('./browser-server')
    await worker.start({ onUnhandledRequest: 'bypass' })
  }
}

export default function MockProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>) {
  const [mockingReady, setMockingReady] = useState(false)
  const isMockModeEnabled = !!process.env.REACT_APP_MOCK_MODE

  useEffect(() => {
    if (!mockingReady && isMockModeEnabled) {
      enableApiMocking().then(() => setMockingReady(true))
    }
  }, [isMockModeEnabled, mockingReady])

  // Ensures pages do not load before mock service worker is ready
  if (!mockingReady && isMockModeEnabled) {
    return null
  }

  return <>{children}</>
}
